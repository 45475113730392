import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { ResponsiveHeatMap } from '@nivo/heatmap';

import CircularProgressWithLabel from 'components/shared/CircularProgressWithLabel';

interface GroupedRsvp {
  x: string;
  y: number;
}

interface RsvpSubmissionsGroupedByDayTime {
  data: GroupedRsvp[];
  id: string;
}

const RsvpSubmissionDayTimeChart = ({
  data,
}: {
  data: RsvpSubmissionsGroupedByDayTime[];
}) => (
  <Card data-testid='rsvp-submission-by-day-time' sx={{ flex: 2 }}>
    <CardContent sx={{ p: 3 }}>
      <Typography variant='body2'>RSVP submission by day and time</Typography>
      <Box height={240}>
        {data?.length > 0 ? (
          <ResponsiveHeatMap
            axisBottom={{
              tickPadding: 8,
              tickRotation: -45,
              tickSize: 0,
            }}
            axisLeft={{
              tickPadding: 8,
              tickSize: 0,
            }}
            axisTop={null}
            colors={{ scheme: 'blues', type: 'sequential' }}
            data={data}
            emptyColor='#F5F5F5'
            labelTextColor={(d) => {
              const backgroundColor = d.color; // Get the assigned tile color
              // Function to calculate luminance of the color
              const getLuminance = (color: string) => {
                let b, g, r;

                // Extract RGB values from rgb(r, g, b)
                const match = color.match(/\d+/g);
                if (!match) return null;
                [r, g, b] = match.map(Number);

                // Normalize RGB values to 0-1 range
                [r, g, b] = [r / 255, g / 255, b / 255];

                // Calculate luminance
                const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                return luminance;
              };

              const luminance = getLuminance(backgroundColor);

              if (luminance === null) return backgroundColor; // If parsing fails, keep it as is

              return luminance > 0.5 ? '#000' : '#FFF';
            }}
            margin={{ bottom: 50, left: 80, right: 10, top: 10 }}
            opacity={0.9}
          />
        ) : (
          <Stack
            alignItems='center'
            direction='column'
            height='100%'
            justifyContent='center'
          >
            <CircularProgressWithLabel
              isPlaceholder
              label='No data'
              size={180}
              thickness={10}
            />
          </Stack>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default RsvpSubmissionDayTimeChart;
