import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

interface EventSummaryCardProps {
  icon: React.ReactNode;
  title: string;
  value: number | string;
  badge?: string;
  circleColor?: string;
}

const EventSummaryCard: React.FC<EventSummaryCardProps> = ({ icon, title, value, badge, circleColor }) => {
  const renderIconBox = (children: React.ReactNode) => (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.primary.light,
        color: theme => theme.palette.primary.main,
        borderRadius: '10%',
        padding: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 48,
        height: 48,
      }}
    >
      {React.cloneElement(children as React.ReactElement, { size: 32 })}
    </Box>
  );

  const renderBadge = (label: string) => (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: 1,
        padding: '2px 6px',
        fontSize: '0.875rem',
        color: 'text.secondary',
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: '8px',
      }}
    >
      {label}
    </Box>
  );

  const renderCircle = (color: string) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 1.2,
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: color,
      }}
    />
  );

  return (
    (<Grid
      size={{
        xs: 12,
        sm: 6,
        md: 3
      }}>
      <Card sx={{ borderRadius: 2, boxShadow: 2, p: 2 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            {renderIconBox(icon)}
            <Stack>
              <Typography variant="h6" fontWeight="normal">{title}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="h4" fontWeight="bold">{value}</Typography>
                {badge && renderBadge(badge)}
                {circleColor && renderCircle(circleColor)}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>)
  );
};

export default EventSummaryCard;
