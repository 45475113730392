import React from 'react';
import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { AddressBook, UserCirclePlus } from '@phosphor-icons/react';

import RsvpResponseSummaryChart from 'components/Events/Controls/Analytics/Charts/RsvpResponseSummaryChart';
import RsvpSubmissionDayTimeChart from 'components/Events/Controls/Analytics/Charts/RsvpSubmissionDayTimeChart';
import RsvpSubmissionSourceChart from 'components/Events/Controls/Analytics/Charts/RsvpSubmissionSourceChart';
import { EventAnalytic } from 'types/EventAnalytic';
import { hasStarted } from 'utils/event';

interface GuestEngagementProps {
  data: EventAnalytic | undefined;
}

const GuestEngagement: React.FC<GuestEngagementProps> = ({ data }) => {
  const theme = useTheme();
  const eventStarted = hasStarted(data);

  return data?.inviteesCount && data?.inviteesCount > 0 ? (
    <Stack direction='column' gap={3}>
      <RsvpResponseSummaryChart data={data} />
      <Stack
        alignItems='stretch'
        direction='row'
        flexGrow={1}
        gap={4}
        justifyContent={{ xs: 'flex-start' }}
        sx={{ flexWrap: 'wrap' }}
      >
        <RsvpSubmissionSourceChart
          data={data?.rsvpSubmissionsGroupedBySource || []}
        />
        <RsvpSubmissionDayTimeChart
          data={data?.rsvpSubmissionsGroupedByDayAndTime || []}
        />
      </Stack>
    </Stack>
  ) : (
    <Card>
      <CardContent>
        <Stack
          alignItems='center'
          direction='column'
          gap={2}
          justifyContent='center'
        >
          {eventStarted ? (
            <AddressBook color={theme.palette.primary.main} size={32} />
          ) : (
            <UserCirclePlus color={theme.palette.primary.main} size={32} />
          )}
          <Typography variant='body2'>
            {eventStarted
              ? 'No guest engagement data'
              : 'No invitations sent yet'}
          </Typography>
          <Typography variant='body1'>
            {eventStarted
              ? 'No guests were invited to this event'
              : "Invite guests to see your event's guest engagement data"}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GuestEngagement;
