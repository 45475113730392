import React, { useMemo } from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';

import ChartWithLegend from 'components/shared/ChartWithLegend';
import CircularProgressWithLabel from 'components/shared/CircularProgressWithLabel';

interface RsvpSubmissionsGroupedBySource {
  label: string;
  value: number;
}

const RsvpSubmissionSourceChart = ({
  data,
}: {
  data: RsvpSubmissionsGroupedBySource[];
}) => {
  const dataWithColors = useMemo(() => {
    const colors: { [key: string]: string } = {
      Calendar: '#D0E4E6',
      'Event page': '#80C0C7',
      Slack: '#4176A1',
    };

    const mappedData =
      data?.length > 0
        ? data?.map((item) => ({
            ...item,
            color: colors[item.label] || '#F5F5F5',
            id: item.label,
          }))
        : [];
    return mappedData;
  }, [data]);

  return (
    <Card data-testid='rsvp-submission-source' sx={{ flex: 1 }}>
      <CardContent sx={{ p: 3 }}>
        <Typography variant='body2'>RSVP submission source</Typography>
        <Box height={240}>
          {dataWithColors?.length > 0 ? (
            <ChartWithLegend
              Chart={
                <ResponsivePie
                  activeOuterRadiusOffset={8}
                  animate
                  borderWidth={0}
                  colors={(d) => d?.data?.color}
                  cornerRadius={3}
                  data={dataWithColors}
                  fit={false}
                  innerRadius={0.5}
                  layers={['arcs']}
                  margin={{ bottom: 5, left: 10, right: 10, top: 20 }}
                  padAngle={1}
                  sortByValue
                  theme={{
                    text: {
                      fontFamily: `'Inter', sans-serif`,
                    },
                  }}
                  valueFormat={(v: any) => `${v.toFixed(2)}%`}
                />
              }
              legendItems={dataWithColors}
            />
          ) : (
            <Stack
              alignItems='center'
              direction='column'
              height='100%'
              justifyContent='center'
            >
              <CircularProgressWithLabel
                isPlaceholder
                label='No data'
                size={180}
                thickness={10}
              />
            </Stack>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RsvpSubmissionSourceChart;
