/* eslint-disable react/no-multi-comp */
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  alpha,
  createTheme,
  lighten,
  ThemeProvider,
} from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import {
  ArrowDown,
  ArrowsDownUp,
  ArrowUp,
  CalendarBlank,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CaretRight,
  CheckSquare,
  Circle,
  CircleWavyCheck,
  Clock,
  Info,
  MinusSquare,
  RadioButton,
  Square,
  Warning,
  WarningCircle,
} from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { DarkColor } from 'utils/color';

const defaultTheme = createTheme();
const lighterShadows = defaultTheme.shadows.map((s) =>
  s.replace('0,0,0,0.', '0,0,0,0.0')
);

const ftnPalette = createPalette({
  background: {
    default: '#FAFAFA',
  },
  error: {
    dark: '#C62828',
    light: '#FFEBEE',
    main: '#EF5350',
  },
  info: {
    dark: '#0D47A1',
    light: '#E3F2FD',
    main: '#1E88E5',
  },
  mode: 'light',
  neutral: {
    dark: '#616161',
    light: '#E0E0E0',
    main: '#9E9E9E',
  },
  otherBlue: {
    contrastText: '#333',
    dark: '#305687',
    light: '#BCD8FC',
    main: '#4D89D6',
  },
  otherGreen: {
    contrastText: '#333',
    dark: '#5B6A1C',
    light: '#E0E9BA',
    main: '#92A544',
  },
  otherOrange: {
    contrastText: '#333',
    dark: '#C35E26',
    light: '#F9D0BA',
    main: '#E49826',
  },
  otherPink: {
    contrastText: '#333',
    dark: '#A45084',
    light: '#EDCFE2',
    main: '#D975B2',
  },
  otherPurple: {
    contrastText: '#333',
    dark: '#634794',
    light: '#DCC9FE',
    main: '#A783EA',
  },
  otherYellow: {
    contrastText: '#333',
    dark: '#C17900',
    light: '#F3E4B1',
    main: '#F1C841',
  },
  primary: {
    dark: '#025963',
    light: '#CEE7E7',
    main: '#008290',
  },
  secondary: {
    dark: '#013a67',
    light: '#DEEAF7',
    main: '#014881',
  },
  success: {
    dark: '#1B5E20',
    light: '#E8F5E9',
    main: '#43A047',
  },
  warning: {
    dark: '#C17900',
    light: '#FFF8E1',
    main: '#F4B63F',
  },
});

const buttonPadding = {
  medium: 8,
  small: 4,
};

const buttonTransitionProps = {
  transition: [
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  ].join(' '),
};

const getButtonPadding = ({ ownerState, size }) => {
  const p = buttonPadding[size];
  return {
    paddingBottom: p,
    paddingTop: p,
    ...(ownerState.variant === 'outlined'
      ? {
          paddingBottom: p - 1,
          paddingTop: p - 1,
        }
      : {}),
  };
};

const getBorderedButtonVariant = (forComponent = null) => {
  const borderColor = ftnPalette.grey[700];
  const color = ftnPalette.grey[700];
  const disabledColor = ftnPalette.grey[500];

  const accentProps = {
    backgroundColor: ftnPalette.grey[200],
    borderColor,
    color,
  };

  const colorProps = {
    borderColor,
    color: borderColor,
  };

  const disabledProps = {
    borderColor: `${disabledColor} !important`,
    color: `${disabledColor} !important`,
  };

  return {
    props: {
      variant: 'bordered',
    },
    style: {
      borderRadius: 6,
      ...colorProps,
      ...(forComponent === null
        ? {
            '&:hover': accentProps,
            backgroundColor: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            paddingBottom: 7,
            paddingTop: 7,
          }
        : {}),
      '& .Mui-disabled': disabledProps,
      '&.MuiButton-sizeSmall': {
        paddingBottom: 3,
        paddingTop: 3,
      },
      '.MuiToggleButton-root': {
        ...colorProps,
        '& .Mui-disabled': disabledProps,
        '&.Mui-selected': accentProps,
        '&:hover': {
          color,
        },
      },
      textTransform: 'none',
    },
  };
};

const inputPadding = {
  horizontal: 10,
  vertical: 10.5,
};
const inputPaddingVal = `${inputPadding.vertical}px ${inputPadding.horizontal}px`;

const ftnTheme = createTheme({
  breakpoints: {
    values: {
      lg: 992,
      md: 768,
      sm: 576,
      xl: 1350,
      xs: 0,
      xxl: 1500,
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-expanded:before': {
            opacity: 1,
          },
          ...(ownerState?.square
            ? {}
            : {
                '&:first-of-type': {
                  borderRadius: 6,
                },
                borderRadius: 6,
              }),
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          '& .MuiFormControl-root': {
            margin: 0,
          },
          paddingTop: 0,
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <CaretDown size={20} />,
      },
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            borderBottom: `1px solid ${ftnPalette.grey[300]}`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          borderRadius: 6,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: (
            <WarningCircle
              color={ftnPalette.error.main}
              size={32}
              weight='fill'
            />
          ),
          info: <Info color={ftnPalette.info.main} size={32} weight='fill' />,
          success: (
            <CircleWavyCheck
              color={ftnPalette.success.main}
              size={32}
              weight='fill'
            />
          ),
          warning: (
            <Warning color={ftnPalette.warning.main} size={32} weight='fill' />
          ),
        },
      },
      styleOverrides: {
        action: {
          marginRight: 0,
          padding: 0,
        },
        icon: {
          flexDirection: 'column',
          justifyContent: 'center',
        },
        message: {
          padding: '0.75rem 0',
        },
        root: {
          alignItems: 'center',
          borderLeftStyle: 'solid',
          borderLeftWidth: 4,
          fontSize: 14,
          fontWeight: 400,
        },
        standardError: {
          backgroundColor: ftnPalette.error.light,
          borderLeftColor: ftnPalette.error.main,
        },
        standardInfo: {
          backgroundColor: ftnPalette.info.light,
          borderLeftColor: ftnPalette.info.main,
        },
        standardSuccess: {
          backgroundColor: ftnPalette.success.light,
          borderLeftColor: ftnPalette.success.main,
        },
        standardWarning: {
          backgroundColor: ftnPalette.warning.light,
          borderLeftColor: ftnPalette.warning.main,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: ftnPalette.grey[900],
          fontWeight: 500,
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: ftnPalette.background.default,
          boxShadow: 'none',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        autoHighlight: true,
        ChipProps: {
          size: 'small',
        },
      },
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            padding: 0,
          },
        },
        root: ({ theme }) => ({
          '& .MuiAutocomplete-tag': {
            margin: 0,
            paddingBottom: theme.spacing(0.25),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.25),
          },
          '& .MuiInputBase-root': {
            gap: theme.spacing(1),
            paddingBottom: inputPadding.vertical - 2,
            paddingTop: inputPadding.vertical - 2,
          },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        rounded: {
          borderRadius: 6,
        },
        // TODO: Replace instances of square with "rounded", then remove this
        square: {
          borderRadius: 6,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          borderColor: 'white',
        },
        root: {
          '& .MuiAvatar-root': {
            borderColor: 'white',
          },
        },
      },
    },
    MuiBadge: {
      variants: [
        {
          props: {
            variant: 'light',
          },
          style: ({ ownerState, theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor:
                ownerState?.color && ownerState?.color !== 'default'
                  ? theme.palette[ownerState.color].light.replace('##', '#')
                  : theme.palette.primary.light,
              color:
                ownerState?.color && ownerState?.color !== 'default'
                  ? theme.palette[ownerState.color].dark
                  : theme.palette.primary.dark,
            },
          }),
        },
      ],
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <CaretRight size={16} />,
      },
      styleOverrides: {
        li: {
          a: {
            '&:hover': {
              color: ftnPalette.primary.main,
            },
            color: ftnPalette.text.secondary,
            textDecoration: 'none',
          },
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        containedError: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.error.main,
          },
          backgroundColor: theme.palette.error.dark,
        }),
        outlined: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: alpha(
              ownerState?.color && theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].light
                : theme.palette.primary.light,
              0.7
            ),
          },
          backgroundColor: ownerState?.color === 'neutral' ? '#F5F5F5' : null,
          borderColor: alpha(
            ownerState?.color && theme.palette[ownerState.color]
              ? theme.palette[ownerState.color].main
              : theme.palette.primary.main,
            0.8
          ),
          borderWidth: '1.3px !important',
          ...(ownerState.color === 'neutral'
            ? { color: theme.palette.neutral.dark }
            : {}),
        }),
        root: ({ ownerState, theme }) => ({
          borderRadius: 6,
          fontSize: 16,
          textTransform: 'none',
          ...(ownerState.color === 'neutral' &&
          ownerState.variant !== 'contained'
            ? {
                '&:hover': {
                  backgroundColor: alpha(theme.palette.neutral.light, 0.7),
                },
                color: theme.palette.neutral.dark,
              }
            : {}),
        }),
        sizeMedium: ({ ownerState }) =>
          getButtonPadding({ ownerState, size: 'medium' }),
        sizeSmall: ({ ownerState }) =>
          getButtonPadding({ ownerState, size: 'small' }),
      },
      variants: [
        getBorderedButtonVariant(),
        {
          props: {
            variant: 'outlined-dark',
          },
          style: ({ ownerState, theme }) => ({
            '&:hover': {
              backgroundColor: alpha(
                ownerState?.color && theme.palette[ownerState.color]
                  ? theme.palette[ownerState.color].main
                  : theme.palette.primary.main,
                0.2
              ),
            },
            borderColor: alpha(
              ownerState?.color && theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].dark
                : theme.palette.primary.dark,
              0.8
            ),
            borderStyle: 'solid',
            borderWidth: '1px',
            color:
              ownerState?.color && theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].dark
                : theme.palette.primary.dark,
            ...(ownerState.color === 'neutral'
              ? { color: theme.palette.neutral.dark }
              : {}),
          }),
        },
        {
          props: {
            variant: 'outlinedLight',
          },
          style: ({ ownerState, theme }) => ({
            '&:hover': {
              backgroundColor: alpha(
                ownerState?.color && theme.palette[ownerState.color]
                  ? theme.palette[ownerState.color].light
                  : theme.palette.common.white,
                0.2
              ),
            },
            borderColor: alpha(
              ownerState?.color && theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].light
                : theme.palette.common.white,
              0.8
            ),
            borderStyle: 'solid',
            borderWidth: 1,
            color:
              ownerState?.color && theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].light
                : theme.palette.common.white,
          }),
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            alignItems: 'stretch',
            flexDirection: 'column-reverse',
          },
          '>:not(:first-of-type)': {
            marginLeft: 'unset',
          },
          gap: theme.spacing(1),
          justifyContent: 'flex-end',
          padding: '8px 16px 16px',
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '> .MuiFormControl-root:first-of-type': {
            marginTop: 0,
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h5',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckSquare size={29} weight='fill' />,
        color: 'primary',
        icon: <Square size={29} weight='thin' />,
        indeterminateIcon: <MinusSquare size={29} weight='fill' />,
      },
      styleOverrides: {
        root: {
          '& svg rect:nth-of-type(2)': {
            fill: 'white',
          },
          padding: 4,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // TODO: Find any instances of this, add variant="light", then remove this from the theme
        colorWarning: {
          backgroundColor: ftnPalette.warning.light,
          color: ftnPalette.warning.dark,
        },
        deleteIcon: {
          marginLeft: 0,
          marginRight: 0,
        },
        icon: {
          margin: 0,
          padding: 0,
        },
        label: ({ theme }) => ({
          display: 'block',
          fontSize: theme.typography.overline.fontSize,
          fontWeight: 400,
          padding: 0,
        }),
        labelMedium: ({ theme }) => ({
          fontSize: theme.typography.body1.fontSize,
          lineHeight: 1.2,
        }),
        root: ({ theme }) => ({
          alignItems: 'center',
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          height: 'auto',
          paddingBottom: theme.spacing(0.75),
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          paddingTop: theme.spacing(0.75),
        }),
      },
      variants: [
        {
          props: {
            variant: 'light',
          },
          style: ({ ownerState, theme }) => ({
            '&:hover': {
              backgroundColor:
                ownerState?.color && !!theme.palette[ownerState.color]
                  ? alpha(theme.palette[ownerState.color].main, 0.4)
                  : ownerState?.color === 'default'
                  ? theme.palette.grey[200]
                  : alpha(theme.palette.primary.main, 0.4),
            },
            backgroundColor:
              ownerState?.color && !!theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].light
                : ownerState?.color === 'default'
                ? theme.palette.grey[100]
                : theme.palette.primary.light,
            color:
              ownerState?.color && !!theme.palette[ownerState.color]
                ? theme.palette[ownerState.color].dark
                : ownerState?.color === 'default'
                ? theme.palette.grey[900]
                : theme.palette.primary.dark,
            fontWeight: 500,
          }),
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter');
        }
        a {
          color: ${themeParam.palette.primary.main}
        }
      `,
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        initialState: {
          pagination: {
            pageSize: 10,
          },
        },
        pageSizeOptions: [10, 25, 50, 100],
        showColumnVerticalBorder: false,
        slotProps: {
          filterPanel: {
            // Display columns by ascending alphabetical order
            columnsSort: 'asc',
            filterFormProps: {
              columnInputProps: {
                size: 'small',
                sx: { mt: 'auto' },
                variant: 'outlined',
              },
              // Customize inputs by passing props
              logicOperatorInputProps: {
                size: 'small',
                variant: 'outlined',
              },
              operatorInputProps: {
                size: 'small',
                sx: { mt: 'auto' },
                variant: 'outlined',
              },
              valueInputProps: {
                InputComponentProps: {
                  notched: false,
                  size: 'small',
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': { top: 0 },
                  },
                  variant: 'outlined',
                },
                notched: false,
                size: 'small',
                variant: 'outlined',
              },
            },
          },
        },
        slots: {
          ColumnSortedAscendingIcon: ArrowDown,
          ColumnSortedDescendingIcon: ArrowUp,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ColumnUnsortedIcon: ({ sortingOrder, ...other }) => (
            <ArrowsDownUp size={20} {...other} />
          ),
        },
      },
      styleOverrides: {
        columnHeader: ({ theme }) => ({
          '&:first-of-type:last-of-type': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        }),
        columnHeaderRow: {
          backgroundColor: ftnPalette.grey[100],
        },
        columnHeaders: {
          borderColor: ftnPalette.grey[300],
        },
        columnHeaderTitle: ({ theme }) => ({
          fontSize: theme.typography.overline.fontSize,
        }),
        filterFormValueInput: {
          '.MuiOutlinedInput-notchedOutline legend': {
            display: 'none',
          },
          margin: 0,
        },
        footerContainer: ({ theme }) => ({
          backgroundColor: theme.palette.grey[50],
        }),
        iconButtonContainer: {
          visibility: 'visible !important',
          width: 'auto',
        },
        root: ({ theme }) => ({
          '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
            {
              opacity: 0.5,
            },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within':
            {
              outline: 'none !important',
            },
          '&.MuiDataGrid-root--densityComfortable': {
            '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell.MuiDataGrid-cellCheckbox':
              {
                justifyContent: 'flex-end',
              },
            '& .MuiDataGrid-columnHeader:NOT(.MuiDataGrid-columnHeaderCheckbox), .MuiDataGrid-cell:NOT(.MuiDataGrid-cellCheckbox)':
              {
                paddingLeft: theme.spacing(2.5),
                paddingRight: theme.spacing(2.5),
              },
            '& .MuiDataGrid-row, & .MuiDataGrid-cell': {
              height: `76px !important`,
              maxHeight: `76px !important`,
              minHeight: `76px !important`,
            },
          },
          fontSize: 16,
          fontWeight: 400,
        }),
        row: ({ theme }) => ({
          '&.Mui-hovered': {
            backgroundColor: theme.palette.grey[50],
          },
        }),
        sortIcon: {
          visibility: 'visible !important',
          width: 'auto',
        },
        virtualScrollerContent: {
          backgroundColor: 'white',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: (props) => (
            <CalendarBlank color={ftnPalette.grey[600]} {...props} />
          ),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          '>:not(style)~:not(style)': {
            marginLeft: 0,
          },
          gap: theme.spacing(1.5),
          padding: '8px 24px 24px',
          textAlign: 'right',
        }),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[900],
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
    // TODO: Remove this from the theme, then find instances that aren't
    //       wrapped in a Stack with [spacing] or [gap] and wrap them
    //       with <Stack gap={1} />
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.margin === 'none'
            ? {
                margin: 0,
              }
            : { marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }),
        }),
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '.MuiFormControlLabel-root': {
            marginLeft: -6,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[900],
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.4,
          paddingBottom: theme.spacing(0.75),
          whiteSpace: 'normal',
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          '.MuiIconButton-root': {
            // marginLeft: -4,
            marginRight: -12,
          },
          marginLeft: 0,
        },
        positionStart: {
          marginRight: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${lighten(
              ftnPalette.primary.light,
              0.5
            )} inset !important`,
            WebkitTextFillColor: `${ftnPalette.text.primary} !important`,
          },
          padding: inputPaddingVal,
        },
        root: {
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
          backgroundColor: 'white',
          borderRadius: 6,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: DarkColor,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.4,
          paddingBottom: theme.spacing(0.75),
          position: 'relative',
          transform: 'none',
          transition: 'none',
          whiteSpace: 'normal',
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiListItem: {
      variants: [
        {
          props: {
            className: 'alignTop',
          },
          style: {
            '& .MuiListItemIcon-root': {
              '+ .MuiListItemText-root.MuiListItemText-multiline': {
                margin: 0,
              },
              alignItems: 'center',
              alignSelf: 'flex-start',
              flexDirection: 'row',
              minHeight: 24,
              minWidth: 36,
            },
            alignItems: 'flex-start',
          },
        },
      ],
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body1',
        },
        secondaryTypographyProps: {
          color: 'text.secondary',
          variant: 'overline',
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiMenuItem-root': {
            borderRadius: 6,
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
          },
          '& [class*="MuiMultiSectionDigitalClockSection-root"]:after': {
            // align selected hour, minute, and meridian when the time picker opens
            // this was needed b/c we changed the height of the options (via pt & pb)
            height: 'calc(100% - 32px)',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        input: {
          padding: inputPaddingVal,
        },
        inputSizeSmall: {
          padding: '8.5px 10px',
        },
        notchedOutline: ({ theme }) => ({
          ...buttonTransitionProps,
          borderColor: theme.palette.grey[300],
        }),
        root: ({ theme }) => ({
          '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500],
          },
          borderRadius: 6,
        }),
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'secondary',
        shape: 'rounded',
      },
    },
    MuiPaginationItem: {
      defaultProps: {
        slots: {
          first: CaretDoubleLeft,
          last: CaretDoubleRight,
          next: CaretRight,
          previous: CaretLeft,
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '& .MuiPickersLayout-actionBar': {
            padding: 0,
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        // INFO: @phosphor-icons/react uses the "size" value on the SVG element that wraps each icon.
        //       However, the actual content of the SVG (i.e. the visible icon) ends up rendering
        //       smaller than the "size", due to built-in spacing around the content.
        //       By setting the size to 30.12, we're rendering an SVG that is 30.12px squared,
        //       but the actual content is rendered as 24px squared.
        checkedIcon: <RadioButton size={30.12} weight='fill' />,
        color: 'secondary',
        icon: <Circle size={30.12} weight='light' />,
      },
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        input: {
          padding: inputPaddingVal,
        },
        inputSizeSmall: {
          padding: '8.5px 10px',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          margin: '0 16px',
        },
        root: {
          flex: '0 1 96px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: ftnPalette.grey[900],
          },
          color: ftnPalette.grey[600],
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: ftnPalette.primary.main,
            fontWeight: 600,
          },
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'success',
      },
      styleOverrides: {
        sizeMedium: {
          padding: 7,
        },
        switchBase: {
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
        },
        thumb: {
          backgroundColor: 'white',
        },
        track: {
          borderRadius: 20,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: ftnPalette.grey[700],
          fontSize: 16,
          fontWeight: 500,
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: 14,
          fontWeight: 500,
        },
        root: {
          '&.MuiTableCell-head': {
            fontWeight: 500,
          },
          fontWeight: 400,
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        actions: {
          justifySelf: 'flex-end',
          marginLeft: 0,
        },
        displayedRows: ({ theme }) => ({
          marginRight: theme.spacing(2),
        }),
        root: {
          '& .MuiFormLabel-root.MuiInputLabel-root': {
            paddingBottom: '0 !important',
          },
          '& .MuiInputBase-root.MuiInput-root': {
            backgroundColor: 'transparent !important',
          },
        },
        toolbar: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap !important',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${ftnPalette.grey[400]}`,
          marginBottom: 0,
          width: '100%',
        },
      },
    },
    MuiTimePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: (props) => (
            <Clock color={ftnPalette.grey[600]} {...props} />
          ),
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        variant: 'bordered',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '& .MuiToggleButton-root': {
            fontSize: 16,
            textTransform: 'none',
            ...(ownerState.orientation === 'horizontal'
              ? {
                  paddingBottom: theme.spacing(0.875),
                  paddingTop: theme.spacing(0.875),
                }
              : {}),
          },
          '& .MuiToggleButton-root, .MuiToggleButton-grouped:not(:first-of-type)':
            {
              borderColor: ownerState?.disabled
                ? theme.palette.grey[500]
                : ownerState?.color && theme.palette[ownerState.color]
                ? ownerState.color.includes('neutral')
                  ? theme.palette.grey[500]
                  : theme.palette[ownerState.color].main
                : theme.palette.primary.main,
              borderStyle: 'solid',
              color: ownerState?.disabled
                ? theme.palette.grey[500]
                : ownerState?.color && theme.palette[ownerState.color]
                ? ownerState.color.includes('neutral')
                  ? theme.palette.grey[700]
                  : theme.palette[ownerState.color].main
                : theme.palette.primary.main,
              ...buttonTransitionProps,
              '&.Mui-selected': {
                backgroundColor: `${
                  ownerState?.disabled
                    ? theme.palette.grey[200]
                    : ownerState?.color && theme.palette[ownerState.color]
                    ? ownerState.color.includes('neutral')
                      ? theme.palette.grey[200]
                      : theme.palette[ownerState.color].light
                    : theme.palette.primary.light
                } !important`,
                color: ownerState?.disabled
                  ? theme.palette.grey[500]
                  : ownerState?.color && theme.palette[ownerState.color]
                  ? ownerState.color.includes('neutral')
                    ? theme.palette.grey[800]
                    : theme.palette[ownerState.color].dark
                  : theme.palette.primary.dark,
              },
              '&:hover': {
                backgroundColor: alpha(
                  ownerState?.disabled
                    ? theme.palette.grey[500]
                    : ownerState?.color && theme.palette[ownerState.color]
                    ? ownerState.color.includes('neutral')
                      ? theme.palette.grey[100]
                      : theme.palette[ownerState.color].light
                    : theme.palette.primary.light,
                  0.7
                ),
              },
            },
          '& .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-firstButton': {
            borderTopLeftRadius: theme.spacing(0.75),
            ...(ownerState.orientation === 'horizontal'
              ? {
                  borderBottomLeftRadius: theme.spacing(0.75),
                }
              : {
                  borderTopRightRadius: theme.spacing(0.75),
                }),
          },
          '& .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-lastButton': {
            borderBottomRightRadius: theme.spacing(0.75),
            ...(ownerState.orientation === 'horizontal'
              ? {
                  borderTopRightRadius: theme.spacing(0.75),
                }
              : {
                  borderBottomLeftRadius: theme.spacing(0.75),
                }),
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: ({ theme }) => ({
          '&:before': {
            border: `1px solid ${theme.palette.grey[900]}`,
          },
          color: theme.palette.grey[900],
        }),
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.grey[900],
          fontSize: 14,
          lineHeight: '140%',
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },
  palette: ftnPalette,
  shadows: lighterShadows,
  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    caption: {
      // x-small
      fontSize: 12,
      lineHeight: 1.3,
    },
    fontFamily: [
      'Inter',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 30,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 14,
      lineHeight: 1.4,
      // small
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: 22,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 500,
    },
  },
});

// eslint-disable-next-line react/no-multi-comp
const FtnThemeProvider = ({ children }) => (
  <ThemeProvider theme={ftnTheme}>
    <CssBaseline>{children}</CssBaseline>
  </ThemeProvider>
);

FtnThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default FtnThemeProvider;
