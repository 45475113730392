import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  CalendarCheck,
  CheckCircle,
  DownloadSimple,
  PiggyBank,
  UserCirclePlus,
} from '@phosphor-icons/react';

import EventNpsChart from 'components/Events/Controls/Analytics/Charts/EventNpsChart';
import GuestListSummary from 'components/Events/Controls/Analytics/Charts/GuestListSummary';
import EventSummaryCard from 'components/Events/Controls/Analytics/EventSummaryCard';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventSummaryPastProps {
  data: EventAnalytic | undefined;
  generatePDF: any;
  hideButton: boolean;
}

const EventSummaryPast: React.FC<EventSummaryPastProps> = ({
  data,
  generatePDF,
  hideButton,
}) => {
  const amountCents = data?.budget?.amountCents || 0;
  const actualSpendCents = data?.budget?.actualSpendCents || 0;
  let circleColor = 'transparent';

  if (amountCents > 0) {
    circleColor =
      actualSpendCents > amountCents ? 'error.main' : 'primary.main';
  }

  return (
    <Stack direction='column' gap={2}>
      <Stack
        alignItems='flex-end'
        direction='row'
        justifyContent='space-between'
      >
        <Typography component='h2' variant='h4'>
          Overview
        </Typography>
        {!hideButton && (
          <Button
            className='analytics-section-button'
            data-testid='export-analytics-data'
            endIcon={<DownloadSimple />}
            onClick={() => generatePDF()}
            variant='text'
          >
            Export analytics
          </Button>
        )}
      </Stack>
      <Grid container spacing={3}>
        <EventSummaryCard
          icon={<UserCirclePlus />}
          title='Invitations sent'
          value={data?.inviteesCount || 0}
        />

        <EventSummaryCard
          badge={`${Math.round(
            ((data?.statusCounts?.rsvp || 0) / (data?.inviteesCount || 1)) * 100
          )}%`}
          icon={<CheckCircle />}
          title='Accepted RSVPs'
          value={data?.statusCounts?.rsvp || 0}
        />

        <EventSummaryCard
          badge={`${Math.round(
            ((data?.attendedCounts?.yes || 0) / (data?.inviteesCount || 1)) *
              100
          )}%`}
          icon={<CalendarCheck />}
          title='Attendees'
          value={data?.attendedCounts?.yes || 0}
        />

        <EventSummaryCard
          circleColor={circleColor}
          icon={<PiggyBank />}
          title='Current spend'
          value={`$${(data?.budget?.actualSpendCents || 0) / 100}`}
        />
      </Grid>
      <Grid>
        <Stack direction='row' gap={2}>
          <EventNpsChart data={data} />
          <GuestListSummary
            attendees={data?.attendedCounts?.yes}
            invitationsSent={data?.inviteesCount}
            rsvpYes={data?.statusCounts?.rsvp}
            surveyRespondents={data?.totalUniquePostSurveyResponders}
          />
        </Stack>
      </Grid>
    </Stack>
  );
};

export default EventSummaryPast;
