import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const eventAnalyticsUpcomingQueryDocument = gql`
  query eventAnalyticsUpcomingQueryDocument($id: ID!, $timeZone: String!) {
    eventAnalytics(id: $id) {
      id
      start
      ends
      name
      daysUntilEvent
      hoursUntilEvent
      inviteesCount
      statusCounts {
        rsvp
        maybeAttending
        notAttending
        notResponded
      }
      rsvpSubmissionsGroupedByDayAndTime(timeZone: $timeZone)
      rsvpSubmissionsGroupedBySource
      budget {
        actualSpendCents
        amountCents
      }
      postEventSurvey {
        id
      }
      totalUniquePostSurveyResponders
    }
  }
`;

const eventAnalyticsPastQueryDocument = gql`
  query eventAnalyticsPastQueryDocument($id: ID!, $timeZone: String!) {
    eventAnalytics(id: $id) {
      id
      start
      ends
      name
      inviteesCount
      attendedCounts {
        yes
        no
      }
      statusCounts {
        rsvp
        maybeAttending
        notAttending
        notResponded
      }
      budget {
        actualSpendCents
        amountCents
      }
      nps
      npsResponseCounts {
        promoter
        detractor
        passive
        total
      }
      npsResponseRates {
        promoter
        detractor
        passive
      }
      totalUniquePostSurveyResponders
      rsvpSubmissionsGroupedBySource
      rsvpSubmissionsGroupedByDayAndTime(timeZone: $timeZone)
      postEventSurvey {
        id
        feedbackWordCloud
      }
      totalUniquePostSurveyResponders
      npsGroupedByContent
    }
  }
`;
export const useEventAnalytics = ({
  enabled,
  eventId,
  upcoming,
}: {
  enabled: boolean;
  eventId: string;
  upcoming: boolean;
}) => {
  const graphQLClient = useGraphQLClient();
  const userTimezone = dayjs.tz.guess();

  return useQuery<any>({
    enabled,
    queryFn: async () => {
      const response = await graphQLClient?.request(
        upcoming
          ? eventAnalyticsUpcomingQueryDocument
          : eventAnalyticsPastQueryDocument,
        { id: eventId, timeZone: userTimezone }
      );
      return response;
    },
    queryKey: ['event', eventId, upcoming ? 'upcoming' : 'past'],
  });
};
