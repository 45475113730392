import React, { useContext } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { Plugs } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import EventGroupsAutocomplete from 'components/Event/EventGroupsAutocomplete';
import SummaryField from 'components/shared/SummaryField';
import { GroupsContext } from 'contexts/GroupsContext';
import {
  EventDetailsFormControl,
  EventDetailsFormData,
  EventDetailsFormResetField,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';
import { Group } from 'types/Group';

interface EventGroupsFieldProps extends UseFormReturn<EventDetailsFormData> {
  control: EventDetailsFormControl;
  disableFields: boolean;
  isLoading?: boolean;
  resetField: EventDetailsFormResetField;
  watch: EventDetailsFormWatch;
}

const EventGroupsField = ({
  control,
  isLoading = false,
  watch,
}: EventGroupsFieldProps) => {
  const [groups] = useContext(GroupsContext);
  const eventBudgetFlagEnabled = useFlag('event-budget');

  const groupIdsWatch = watch('groupIds');

  const eventGroupsForm = useForm<{ groups: Group[] }>({
    criteriaMode: 'all',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    values: {
      groups: groups?.filter((g: Group) => groupIdsWatch?.includes(g.id)),
    },
  });

  return (
    <Controller
      control={control}
      name='groupIds'
      render={({ field }) => (
        <SummaryField
          component={
            <form>
              <EventGroupsAutocomplete
                form={eventGroupsForm}
                groupIdsValue={field?.value || []}
              />
            </form>
          }
          hasChanges={eventGroupsForm?.formState?.isDirty}
          Icon={Plugs}
          isLoading={isLoading}
          onSubmit={() => {
            field?.onChange(
              eventGroupsForm?.getValues('groups')?.map((g) => g.id)
            );
          }}
          primary='Linked groups'
          secondary={[
            'Connect events to groups to',
            ...(eventBudgetFlagEnabled
              ? ['subtract event expenses from your group budgets and']
              : []),
            'contribute to group analytics.',
          ].join(' ')}
          type='dialog'
          value={field?.value}
          valueType='checkmark'
        />
      )}
      rules={{
        validate: (value) => {
          if (!eventBudgetFlagEnabled) return true;
          const selectedGroups = groups?.filter((g: Group) =>
            value?.includes(g.id)
          );
          const groupCurrencies = new Set(
            selectedGroups?.map((g: Group) => g?.currency_details?.name)
          );
          return (
            groupCurrencies.size <= 1 ||
            'All selected groups must have the same currency'
          );
        },
      }}
    />
  );
};

export default EventGroupsField;
