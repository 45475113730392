import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { CaretUpDown } from '@phosphor-icons/react';

interface EventDuplicateDialogProps {
  copies: number;
  handleClose: () => void;
  handleCopiesChange: (value: number) => void;
  handleDuplicate: () => void;
  isWorking: boolean;
  open: boolean;
}

const EventDuplicateDialog = ({
  copies,
  handleClose,
  handleCopiesChange,
  handleDuplicate,
  isWorking,
  open,
}: EventDuplicateDialogProps) => {
  const menuValues = Array.from([...Array(20).keys()].map((i) => i + 1));

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Duplicate event</DialogTitle>
      <DialogContent>
        <Stack>
          <DialogContentText>
            Please select how many duplicated events you would like to create.
            You can duplicate this event up to 20 times. The new events will be
            created with the same details as the original.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id='number-of-duplicates-label'>
              Select the number of duplicates
            </InputLabel>
            <Select
              IconComponent={CaretUpDown}
              id='number-of-duplicates-select'
              label='number-of-duplicates'
              labelId='number-of-duplicates-label'
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
              onChange={(e) => handleCopiesChange(Number(e.target.value))}
              value={copies}
            >
              {menuValues.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          data-testid='cancel'
          onClick={handleClose}
          variant='bordered'
        >
          Cancel
        </Button>
        <LoadingButton
          color='primary'
          loading={isWorking}
          onClick={handleDuplicate}
          variant='contained'
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EventDuplicateDialog;
