import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

interface GuestListSummaryProps {
  attendees?: number;
  invitationsSent?: number;
  rsvpYes?: number;
  surveyRespondents?: number;
}

const GuestListSummary = ({
  attendees,
  invitationsSent,
  rsvpYes,
  surveyRespondents,
}: GuestListSummaryProps) => {
  const theme = useTheme();
  const primary = (theme as any).palette.otherBlue;
  const secondary = (theme as any).palette.secondary;
  const grey100 = (theme as any).palette.grey[100];

  const data = useMemo(
    () => [
      {
        color:
          surveyRespondents && surveyRespondents > 0
            ? secondary.light
            : grey100,
        id: 'Survey respondents',
        value: surveyRespondents || 0,
      },
      {
        color: attendees && attendees > 0 ? primary.light : grey100,
        id: 'Attendees',
        value: attendees || 0,
      },
      {
        color: rsvpYes && rsvpYes > 0 ? primary.main : grey100,
        id: `Accepted RSVPs`,
        value: rsvpYes || 0,
      },

      {
        color: invitationsSent && invitationsSent > 0 ? primary.dark : grey100,
        id: 'Invitations sent',
        value: invitationsSent || 0,
      },
    ],
    [
      attendees,
      invitationsSent,
      rsvpYes,
      surveyRespondents,
      primary,
      secondary,
      grey100,
    ]
  );

  return (
    <Card sx={{ flex: 1.5 }}>
      <CardContent>
        <Typography variant='body2'>Guest list summary</Typography>
        <Box height={240}>
          <ResponsiveBar
            animate={false}
            ariaLabel='Guest list summary'
            axisBottom={null}
            axisLeft={{
              tickPadding: 16,
              tickSize: 0,
            }}
            axisRight={null}
            axisTop={null}
            borderRadius={3}
            colors={(d: any) => d?.data?.color}
            data={data as any}
            enableGridX={false}
            enableGridY={false}
            labelTextColor={(d) => {
              if (
                d.label === 'Invitations sent' ||
                d.label === 'Accepted RSVPs'
              ) {
                if (d.data.value > 0) {
                  return 'rgba(255, 255, 255, 1)';
                } else {
                  return 'rgba(0, 0, 0, 1)';
                }
              } else {
                return 'rgba(0, 0, 0, 1)';
              }
            }}
            layers={['axes', 'bars']}
            layout='horizontal'
            margin={{ bottom: 20, left: 150, right: 50, top: 20 }}
            role='application'
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontFamily: 'Inter',
                    fontSize: 14,
                  },
                },
              },
              labels: {
                text: {
                  color: (theme as any).palette.primary.light,
                  fontFamily: 'Inter',
                  fontSize: 12,
                },
              },
              text: {
                fontFamily: 'Inter',
              },
            }}
            tooltipLabel={(d) => d.indexValue.toString()}
            valueFormat={(v) => (v > 0 ? `${v}` : '0')}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default GuestListSummary;
