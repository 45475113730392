import React, { useContext, useMemo, useState } from 'react';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Timezone from 'components/Core/Functional/Timezone';
import { OrganizationContext } from 'contexts/OrganizationContext';

const ClampedDropdownStyles = {
  menuList: (base) => ({
    ...base,
    maxHeight: '125px',
  }),
};

const UserInformationForm = ({ onSubmit, user }) => {
  const [office, setOffice] = useState('');
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [org] = useContext(OrganizationContext);

  const officeOpts = useMemo(
    () =>
      _.map(org?.offices, (off) => ({
        label: off.name,
        value: off.id,
      })),
    [org]
  );

  const disabled =
    _.trim(office)?.length === 0 || _.trim(timezone)?.length === 0;

  return (
    <>
      <Timezone label='Timezone' onChange={setTimezone} selected={timezone} />
      {org?.offices?.length === 0 ? (
        <TextField
          fullWidth
          id='office'
          label='Office'
          onChange={(e) => setOffice(e.target.value)}
          placeholder='Add the name of your office'
          value={office}
        />
      ) : (
        <Autocomplete
          onChange={(e, val) => setOffice(val.label)}
          options={officeOpts}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Office'
              placeholder='Select your office'
            />
          )}
          value={office}
        />
      )}
      <Stack direction='row' justifyContent='flex-end' mt={1}>
        <Button
          color='primary'
          data-testid='submit'
          disabled={disabled}
          onClick={() =>
            onSubmit(timezone, office, user?.is_admin || user?.is_organizer)
          }
          variant='contained'
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

export default UserInformationForm;

UserInformationForm.propTypes = {
  onSubmit: PropTypes.func,
  user: PropTypes.shape({
    is_admin: PropTypes.bool,
    is_organizer: PropTypes.bool,
  }),
};
