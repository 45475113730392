import React from 'react';

import EventSummaryPast from 'components/Events/Controls/Analytics/EventSummaryPast';
import EventSummaryUpcoming from 'components/Events/Controls/Analytics/EventSummaryUpcoming';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventOverviewSectionProps {
  data: EventAnalytic | undefined;
  generatePDF?: any;
  hideButton?: boolean;
}

const EventOverviewSection: React.FC<EventOverviewSectionProps> = ({
  data,
  generatePDF,
  hideButton = false,
}) => (
  <>
    {data && new Date(data.ends) > new Date() ? (
      <EventSummaryUpcoming
        data={data}
        generatePDF={generatePDF}
        hideButton={hideButton}
      />
    ) : (
      <EventSummaryPast
        data={data}
        generatePDF={generatePDF}
        hideButton={hideButton}
      />
    )}
  </>
);

export default EventOverviewSection;
