import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { MegaphoneSimple, NotePencil, PlusCircle } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import CollectionAnnouncementDialog from 'components/Collections/CollectionAnnouncementDialog';
import CollectionAnnouncements from 'components/Collections/CollectionAnnouncements';
import CollectionEvents from 'components/Collections/CollectionEvents';
import CollectionPreviewHeader from 'components/Collections/CollectionPreviewHeader';
import ManageCollectionButton from 'components/Collections/ManageCollectionButton';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import SectionWrapper from 'components/shared/SectionWrapper';
import { useEventsCollectionAnnouncementCreate } from 'hooks/useEventsCollectionAnnouncementCreate';
import { useFetchCollection } from 'hooks/useFetchCollection';
import { handleSlackEmojiConversion } from 'utils/text';

const Collection = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: collection, error, isLoading } = useFetchCollection(id!);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const createAnnouncement = useEventsCollectionAnnouncementCreate(() => {
    setDialogOpen(false);
    if (id) {
      queryClient.invalidateQueries({
        queryKey: ['fetchEventsCollectionAnnouncements', id],
      });
    }
  });

  const handleSubmit = async (formData: any) => {
    try {
      createAnnouncement.mutate({
        input: {
          addCalendarInvitation: formData.send_calendar_invites,
          deliveryMethod: 'slack',
          eventsCollectionId: id,
          isScheduled: formData.is_scheduled,
          messageBody: handleSlackEmojiConversion(formData.message_body),
          recipients: [
            {
              recipientIds: formData.slack_channel_ids,
              recipientType: 'SlackChannel',
            },
          ],
          scheduledFor: formData.scheduled_for,
        },
      });
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  if (isLoading) return <div>Loading...</div>;
  if (error || !collection) return <div>Error loading collection.</div>;

  const isCollectionEnded = dayjs(collection.endsAt).isBefore(dayjs());

  return (
    <DefaultLayout
      appBarStyles={{}}
      title={<CollectionPreviewHeader collection={collection} />}
    >
      <Box mt={2}>
        <SectionWrapper title='Manage'>
          <Stack direction='row' justifyContent='space-between'>
            <ManageCollectionButton
              action='Edit collection'
              buttonIcon={<NotePencil />}
              color='primary'
              onClick={() => navigate(`/collections/${id}/edit`)}
              secondaryText='Update the details of your collection'
            />

            <ManageCollectionButton
              action='Announce collection'
              buttonIcon={<MegaphoneSimple weight='fill' />}
              color='warning'
              customStyle={{
                pointerEvents: isCollectionEnded ? 'none' : 'auto',
              }}
              disabled={isCollectionEnded}
              onClick={handleDialogOpen}
              secondaryText='Boost engagement through promotions'
              tooltipText='This collection is in the past and cannot be announced. Announcements are only available for current collections.'
            />
            <ManageCollectionButton
              action='Create collection event'
              buttonIcon={<PlusCircle />}
              color='secondary'
              onClick={() =>
                navigate(`/event/new/create?collection_id=${collection.id}`)
              }
              secondaryText='Publish a new collection event'
            />
          </Stack>
        </SectionWrapper>
      </Box>
      <Box mt={8}>
        <SectionWrapper title='Announcements'>
          <CollectionAnnouncements
            eventsCollectionId={id!}
            isCollectionEnded={isCollectionEnded}
          />
        </SectionWrapper>
      </Box>
      <Box mt={8}>
        <SectionWrapper title='Events'>
          <CollectionEvents
            collection={collection}
            events={collection.events}
          />
        </SectionWrapper>
      </Box>

      <CollectionAnnouncementDialog
        collection={collection}
        isLoading={false}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        open={isDialogOpen}
      />
    </DefaultLayout>
  );
};

export default Collection;
