import React, { useMemo, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  CheckCircle,
  DotsThreeVertical,
  Link,
  NotePencil,
  PaperPlaneTilt,
  Trash,
} from '@phosphor-icons/react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import ConfirmSendSurvey from 'components/Events/Controls/Surveys/ConfirmSendSurvey';
import SurveySection from 'components/Events/Controls/Surveys/SurveySection';
import SurveySettings from 'components/Events/Controls/Surveys/SurveySettings';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getEventLink, isOver } from 'utils/event';
import { SurveyTypes } from 'utils/survey';
import { toHtmlId } from 'utils/text';
import { useContextWithFallback } from 'utils/utils';

const ViewSurveyCard = ({
  handleEditSurvey,
  headerProps,
  responsesCount = 0,
  type,
}) => {
  const {
    actions: { deleteSurvey, sendSurvey },
    state: { event, surveys },
  } = useContextWithFallback(ManagerContext, SurveyTemplatesContext);

  const { copy } = useCopyToClipboard();
  const survey = useMemo(() => surveys[type], [surveys, type]);

  const [isConfirmingSend, setIsConfirmingSend] = useState(false);
  const [isDelete, showDelete] = useState(false);
  const [menuButtonEl, setMenuButtonEl] = useState(null);

  const delay = survey?.delay?.replace('.', ' ');
  const menuIsOpen = Boolean(menuButtonEl);
  const menuId = `${type}-survey-menu`;
  const menuButtonId = `${menuId}-button`;

  const isPreEventSurvey = useMemo(() => type.includes('pre'), [type]);

  const handleMenuOpen = (event) => {
    setMenuButtonEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuButtonEl(null);
  };

  const surveyMenuItems = [
    {
      // show "send" option for post-event survey once event ends
      condition: !isPreEventSurvey && isOver(event),
      Icon: PaperPlaneTilt,
      onClick: () => setIsConfirmingSend(true),
      text: 'Send survey',
    },
    {
      // hide "copy link" option for pre-event survey once event ends
      condition: isPreEventSurvey ? !isOver(event) : true,
      Icon: Link,
      onClick: () => copy(getEventLink('survey', type, event), 'survey link'),
      text: 'Copy survey link',
    },
    {
      // hide "edit" option for pre-event survey once event ends
      condition: isPreEventSurvey ? !isOver(event) : true,
      Icon: NotePencil,
      onClick: handleEditSurvey,
      text: 'Edit survey',
    },
    {
      Icon: Trash,
      onClick: () => showDelete(true),
      text: 'Delete survey',
    },
  ];

  const handleSendSurveys = () => {
    sendSurvey(type, () => setIsConfirmingSend(false));
  };

  return (
    <>
      <Card data-testid='TODO:DATA-CARD-67086'>
        <CardHeader
          action={
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                color='success'
                icon={<CheckCircle size={16} />}
                label='Survey added'
                variant='light'
              />
              <IconButton
                aria-controls={menuIsOpen ? menuId : undefined}
                aria-expanded={menuIsOpen ? 'true' : undefined}
                aria-haspopup='true'
                id={menuButtonId}
                onClick={handleMenuOpen}
                sx={{
                  color: 'grey.900',
                  ...(menuIsOpen ? { bgcolor: 'primary.light' } : {}),
                }}
              >
                <DotsThreeVertical size={32} />
              </IconButton>
              <Menu
                anchorEl={menuButtonEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                id={menuId}
                MenuListProps={{ 'aria-labelledby': menuButtonId }}
                onClose={handleMenuClose}
                open={menuIsOpen}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {surveyMenuItems
                  ?.filter((item) => item.condition !== false)
                  ?.map(({ Icon, onClick, text }) => (
                    <MenuItem key={toHtmlId(text)} onClick={onClick}>
                      <ListItemIcon>
                        <Icon size={20} />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </MenuItem>
                  ))}
              </Menu>
            </Stack>
          }
          subheader={`${responsesCount} responses collected`}
          {...headerProps}
        />
        <CardContent>
          <Stack direction='column' gap={2}>
            <SurveySection
              noDetailsPadding
              title={pluralize('question', survey?.survey_questions?.length, true)}
            >
              <SurveyQuestionList showSummaries type={type} />
              <SurveySettings
                delay={delay || 0}
                description={survey?.description}
                displayResponders={survey?.display_responders}
                event={event}
                type={type}
              />
            </SurveySection>
          </Stack>
        </CardContent>
      </Card>
      <DeleteModal
        noun='survey'
        onCancel={() => showDelete(false)}
        onDelete={(onDone) => {
          deleteSurvey && deleteSurvey(type);
          onDone && onDone();
          showDelete(false);
        }}
        owner={event?.name}
        show={isDelete}
        subject={SurveyTypes?.find((st) => st.value === type)?.label}
      />
      <ConfirmSendSurvey
        handleClose={() => setIsConfirmingSend(false)}
        handleSendSurveys={handleSendSurveys}
        show={isConfirmingSend}
        type={type}
      />
    </>
  );
};

ViewSurveyCard.propTypes = {
  handleEditSurvey: PropTypes.func,
  headerProps: PropTypes.object,
  responsesCount: PropTypes.number,
  type: PropTypes.string,
};

export default ViewSurveyCard;
