import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const eventAnalyticsQueryDocument = gql`
  query eventAnalyticsQueryDocument($id: ID!) {
    eventAnalytics(id: $id) {
      id
      ends
    }
  }
`;
export const useEventAnalyticsCore = ({ eventId }: { eventId: string }) => {
  const graphQLClient = useGraphQLClient();

  return useQuery<any>({
    queryFn: async () =>
      await graphQLClient?.request(eventAnalyticsQueryDocument, {
        id: eventId,
      }),
    queryKey: ['event', eventId, 'analyticsCore'],
  });
};
