import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { timeZonesNames } from '@vvo/tzdb';
import PropTypes from 'prop-types';

/**
 * Renders <TimeZone /> component.
 * @param {object} props
 * @param {string} props.selected current timezone value
 * @param {Function} props.onChange callback for timezone changed
 * @param {string} props.label label for the timezone control
 */
const Timezone = ({ label, onChange, selected }) => {
  const timezoneOptions = timeZonesNames.map((option) => ({
    label: option,
  }));

  const handleChange = (selected) => {
    if (onChange) {
      onChange(timezoneOptions.find((option) => option === selected)?.label);
    }
  };

  return (
    <Autocomplete
      data-testid='TODO:DATA-AUTOCOMPLETE-96447'
      onChange={(e, value) => handleChange(value)}
      options={timezoneOptions}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder='Select timezone' />
      )}
      value={selected}
    />
  );
};

Timezone.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export default Timezone;
