import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  Select,
  TextField,
  Box,
  Stack,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { RocketLaunch, FloppyDisk } from '@phosphor-icons/react';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useAiEventOptionsGenerate } from 'hooks/useAiEventOptionsGenerate';
import { useAiEventCreate } from 'hooks/useAiEventCreate';

interface GenerateEventModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EventOption) => void;
}

interface FormData {
  eventType: string;
  eventDescription: string;
  eventTones: string[];
}

interface GenerateEventOption {
  eventTitle: string;
  eventDescription: string;
  eventDateTime?: string | null;
  eventLocation?: string | null;
}

interface EventOption extends GenerateEventOption {}

const eventTones = ['Casual', 'Formal', 'Inspirational', 'Educational', 'Entertaining'];

const GenerateEventModal: React.FC<GenerateEventModalProps> = ({ open, onClose, onSubmit }) => {
  const { control, watch, setValue, reset } = useForm<FormData>({
    defaultValues: { eventType: '', eventDescription: '', eventTones: [] },
  });

  const [organization] = useContext(OrganizationContext);
  const [step, setStep] = useState(1);
  const [eventOptions, setEventOptions] = useState<EventOption[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<EventOption | null>(null);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { mutate: createEvent, isPending: isCreating } = useAiEventCreate();

  const { mutate: generateEventOptions, isPending } = useAiEventOptionsGenerate((options) => {
    setEventOptions(
      options.map((option) => ({
        ...option
      }))
    );
    setStep(4);
  });

  const eventType = watch('eventType');
  const eventDescription = watch('eventDescription');
  const eventTonesSelected = watch('eventTones');

  useEffect(() => {
    if (!open) {
      reset({ eventType: '', eventDescription: '', eventTones: [] });
      setStep(1);
      setEventOptions([]);
      setSelectedEvent(null);
    }
  }, [open, reset]);

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => {
    setStep((prev) => prev - 1);
    setSelectedEvent(null);
  };

  const handleGenerateOptions = () => {
    setStep(4);
    generateEventOptions({
      input: {
        eventType,
        eventDescription,
        eventTones: eventTonesSelected,
      },
    });
  };

  const handleOptionSelect = (option: EventOption) => {
    setSelectedEvent(option);
  };

  const handleFormSubmit = (status: 'publish' | 'draft') => {
    if (!selectedEvent) return;
  
    if (status === 'draft') setIsSavingDraft(true);
    if (status === 'publish') setIsPublishing(true);
  
    createEvent(
      {
        input: {
          eventType,
          eventTitle: selectedEvent.eventTitle,
          eventDescription: selectedEvent.eventDescription,
          eventDateTime: selectedEvent.eventDateTime || null,
          eventLocation: selectedEvent.eventLocation || null,
          status,
        },
      },
      {
        onSuccess: ({ event }) => {
          setIsSavingDraft(false);
          setIsPublishing(false);
          if (event?.id) {
            const redirectUrl = status === 'publish' 
              ? `/event/${event.id}/details`
              : `/event/${event.id}/create`;
            navigate(redirectUrl);
          }
        },
        onError: () => {
          setIsSavingDraft(false);
          setIsPublishing(false);
        }
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Generate event</DialogTitle>
      <DialogContent>
        <Stack alignItems="stretch" direction="column" gap={3}>
          {step === 1 && (
            <>
              <DialogContentText>
                Tell us what you're thinking for this event!
                <br />
                <span style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                  Consider prompting with event themes, goals, locations, time of day, etc.
                </span>
              </DialogContentText>
              <TextField {...control.register("eventDescription", { required: true })} fullWidth multiline rows={4} />
            </>
          )}

          {step === 2 && (
            <>
              <DialogContentText>What type of event is this?</DialogContentText>
              <FormControl fullWidth>
                <InputLabel>Select event type</InputLabel>
                <Controller
                  name="eventType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} fullWidth displayEmpty>
                      <MenuItem value="" disabled>Select event type</MenuItem>
                      {organization?.event_types?.map((type: { id: number; name: string }) => (
                        <MenuItem key={type.id} value={type.name}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </>
          )}

          {step === 3 && (
            <>
              <DialogContentText>What is the tone of this event? (select all that apply)</DialogContentText>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {eventTones.map((tone) => (
                  <Button
                    key={tone}
                    variant={eventTonesSelected.includes(tone) ? "contained" : "outlined"}
                    onClick={() =>
                      setValue(
                        'eventTones',
                        eventTonesSelected.includes(tone)
                          ? eventTonesSelected.filter((t) => t !== tone)
                          : [...eventTonesSelected, tone]
                      )
                    }
                  >
                    {tone}
                  </Button>
                ))}
              </Box>
            </>
          )}

          {step === 4 && (
            <>
              {isPending ? (
                <>
                  <DialogContentText sx={{ pb: 3 }}>Generating event options for you...</DialogContentText>                    
                  <Box display="flex" justifyContent="center" my={3}>
                    <CircularProgress />
                  </Box>
                </>
              ) : (
                <Stack spacing={1}>
                  <DialogContentText sx={{ pb: 3 }}>We've generated some event options for you to choose from!</DialogContentText>
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Select your event</Typography>
                  <Stack spacing={1}>
                    {eventOptions.map((option, index) => (
                      <Card
                        key={index}
                        onClick={() => handleOptionSelect(option)}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: selectedEvent === option ? theme.palette.primary.main : theme.palette.action.hover,
                          color: selectedEvent === option ? theme.palette.primary.contrastText : 'inherit',
                          padding: 1,
                          borderRadius: 2,
                          transition: 'background-color 0.2s ease-in-out',
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6">{option.eventTitle}</Typography>
                          <Typography variant="overline">{option.eventDescription}</Typography>
                          <Box pt={3}>
                            {option.eventDateTime && (
                              <Typography variant="overline">
                                📅 {option.eventDateTime}
                              </Typography>
                            )}
                          </Box>
                          <Box pt={1}>
                            {option.eventLocation && (
                              <Typography variant="overline">
                                📍 {option.eventLocation}
                              </Typography>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Stack>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {step > 1 && <Button onClick={handleBack} variant="outlined">Back</Button>}
        {step < 3 ? (
          <Button 
            onClick={handleNext} 
            variant="contained" 
            disabled={(step === 1 && !eventDescription) || (step === 2 && !eventType)}
          >
            Next
          </Button>
        ) : step === 3 ? (
          <Button 
            onClick={handleGenerateOptions} 
            variant="contained" 
            disabled={eventTonesSelected.length === 0}
          >
            Next
          </Button>
        ) : (
          <>
            <Button 
              onClick={() => handleFormSubmit('draft')}
              variant="outlined" 
              disabled={!selectedEvent || isSavingDraft || isPublishing}
              startIcon={<FloppyDisk />}
            >
              {isSavingDraft ? 'Saving...' : 'Save draft'}
            </Button>
            <Button 
              onClick={() => handleFormSubmit('publish')}
              variant="contained" 
              disabled={!selectedEvent || isSavingDraft || isPublishing}
              startIcon={<RocketLaunch />}
            >
              {isPublishing ? 'Publishing...' : 'Publish'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GenerateEventModal;
