import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { ConditionalLoader } from 'components/Core/Loader';
import RadioSelect from 'components/Core/RadioSelect';
import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import EditSurveyCard from 'components/Events/Controls/Surveys/EditSurveyCard';
import RightColumnLayout from 'components/shared/layout/RightColumnLayout';
import { useLoadSurveyTemplate } from 'components/Survey/SurveyTemplates/Hooks/TemplateSurveys';
import { AuthContext } from 'contexts/AuthContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { DefaultQuestion } from 'utils/survey';
import { toHtmlId } from 'utils/text';

const SurveyTemplateForm = () => {
  const SurveyStyles = [
    {
      id: 0,
      name: 'Pre-event survey',
      value: 'pre',
    },
    {
      id: 1,
      name: 'Post-event survey',
      value: 'post',
    },
  ];
  const {
    actions: {
      applyMulti,
      applySingle,
      clearPending,
      clearPendingSurvey,
      endTask,
      saveSurveyTemplate,
      setSavedSurveyTemplate,
      setShowErrors,
      setSurveyFieldIdsToDisplayErrorsOn,
      startTask,
    },
    state: {
      isWorking,
      privacy,
      savedSurveyTemplate,
      showErrors,
      style,
      surveyErrors,
      surveys,
      surveyType,
      unsavedChangesEditSurveyTemplate,
    },
  } = useContext(SurveyTemplatesContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [surveyName, setSurveyName] = useState(
    _.get(surveys, `${surveyType}.name`)
  );
  const [selectedSurveyType, setSelectedSurveyType] = useState(style);
  const [tryClose, setTryClose] = useState(false);

  const needsSurveyTemplateLoad = (
    savedSurveyTemplate,
    isWorking,
    location
  ) => {
    if (isWorking === true) {
      return false;
    }
    // No survey loaded
    if (_.isEqual(savedSurveyTemplate, {})) {
      return true;
    }
    // Loaded template is different than requested template
    const pathId = parseInt(location?.pathname?.split('/')[2]);
    if (savedSurveyTemplate?.id !== pathId) {
      return true;
    }
  };

  useLoadSurveyTemplate(
    startTask,
    endTask,
    () => needsSurveyTemplateLoad(savedSurveyTemplate, isWorking, location),
    setSavedSurveyTemplate,
    clearPending,
    applySingle,
    applyMulti,
    surveys
  );

  useEffect(() => {
    setSurveyName(_.get(surveys, `${surveyType}.name`));
  }, [surveyType, surveys]);

  useEffect(() => {
    setSelectedSurveyType(savedSurveyTemplate.style);
  }, [savedSurveyTemplate.style]);

  const onChangeStyle = (v) => {
    const updateSurveyStyle = [v, `surveys.${surveyType}.style`];
    const updateStyleOnPendingData = [v, `style`];
    const newSurveyType = `${v}_event_survey`;

    let questionChanger = [];
    let optionsChanger = [];
    if (newSurveyType === 'post_event_survey') {
      const oldQuestions = _.get(surveys, `pre_event_survey.survey_questions`);
      questionChanger = [
        DefaultQuestion('post_survey', _.last(oldQuestions)?.position + 1 || 0),
        `surveys.${newSurveyType}.survey_questions`,
      ];

      if (oldQuestions) {
        questionChanger = [
          [...oldQuestions, ...questionChanger[0]],
          `surveys.${newSurveyType}.survey_questions`,
        ];
      }
      const oldOptions = _.get(surveys, `pre_event_survey.survey_options`);
      if (oldOptions) {
        optionsChanger = [
          [...oldOptions],
          `surveys.${newSurveyType}.survey_options`,
        ];
      }
    }

    if (newSurveyType === 'pre_event_survey') {
      const npsId = surveys?.post_event_survey?.survey_questions?.find(
        (q) => q.question_type === 'nps'
      )?.id;

      const withoutNpsQuestions = _.cloneDeep(
        surveys.post_event_survey.survey_questions
      );
      const withoutNpsOptions = _.cloneDeep(
        surveys.post_event_survey.survey_options
      );

      withoutNpsQuestions.forEach((q) => {
        if (
          q.question_type === 'nps' ||
          (q.title === 'Any additional feedback you would like to share?' &&
            q.question_type === 'short')
        ) {
          q['_destroy'] = 1;
        }
      });
      withoutNpsOptions.forEach((o) => {
        if (o.survey_question_id === npsId) {
          o['_destroy'] = 1;
        }
      });
      optionsChanger = [
        withoutNpsOptions,
        `surveys.${newSurveyType}.survey_options`,
      ];
      questionChanger = [
        withoutNpsQuestions,
        `surveys.${newSurveyType}.survey_questions`,
      ];
    }
    applyMulti([
      updateSurveyStyle,
      updateStyleOnPendingData,
      questionChanger,
      optionsChanger,
    ]);
    setSelectedSurveyType(v);
  };

  const handleSaveSurvey = () => {
    if (surveyErrors.length > 0 || surveyName?.length < 1) {
      const ids = surveyErrors
        .filter((e) => !_.isNil(e?.questionId) || !_.isNil(e?.optionId))
        .map((e) => e.optionId || e.questionId);
      setSurveyFieldIdsToDisplayErrorsOn(ids);
      setShowErrors(true);
      enqueueSnackbar(
        `We can't save this survey template until all errors have been resolved.`,
        { variant: 'error' }
      );
    } else {
      setShowErrors(false);
      saveSurveyTemplate();
    }
  };

  return (
    <ConditionalLoader
      conditions={[
        !savedSurveyTemplate,
        !savedSurveyTemplate?.creator_id,
        !user?.id,
      ]}
    >
      {savedSurveyTemplate?.creator_id === user?.id || user?.is_admin ? (
        <RightColumnLayout
          button={
            <Button
              data-testid='back-to-survey-templates'
              onClick={() => {
                if (unsavedChangesEditSurveyTemplate) {
                  setTryClose(true);
                } else {
                  navigate('/surveyTemplates');
                }
              }}
              startIcon={<ArrowLeft />}
            >
              Back to survey templates
            </Button>
          }
          columnContent={
            <Stack direction='column' spacing={2}>
              <RadioSelect
                label='Who can see this survey?'
                onChange={(v) =>
                  applySingle(v, `surveys.${surveyType}.privacy`)
                }
                options={[
                  {
                    label: {
                      id: 'private',
                      primary: 'Private',
                      secondary: 'Only me',
                    },
                    value: true,
                  },
                  {
                    label: {
                      id: 'is_shared',
                      primary: 'Shared',
                      secondary: 'All organizers',
                    },
                    value: false,
                  },
                ]}
                value={privacy}
              />
              <FormControl>
                <FormLabel>Survey type</FormLabel>
                <ToggleButtonGroup
                  exclusive
                  onChange={(e, v) => onChangeStyle(v)}
                  value={selectedSurveyType}
                >
                  {SurveyStyles.map((opt) => (
                    <ToggleButton
                      data-testid={`survey-type-${toHtmlId(opt.name)}`}
                      key={opt.id}
                      value={opt.value}
                    >
                      {opt.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </FormControl>

              <>
                <Button
                  color='primary'
                  data-testid='save'
                  onClick={handleSaveSurvey}
                  variant='contained'
                >
                  Save
                </Button>
                <UnsavedChangesModal
                  hasUnsavedChanges={unsavedChangesEditSurveyTemplate}
                  onCancel={() => setTryClose(false)}
                  onConfirm={clearPendingSurvey}
                  surveyTab
                  tryClose={tryClose}
                />
              </>
            </Stack>
          }
          title='Edit survey template'
        >
          <EditSurveyCard type={surveyType}>
            <TextField
              data-testid='survey-template-title'
              error={showErrors && surveyName?.length < 1}
              fullWidth
              helperText={
                showErrors && surveyName?.length < 1
                  ? 'Survey name cannot be empty'
                  : null
              }
              label='Survey template name'
              onChange={(e) => {
                setSurveyName(e.target.value);
                applySingle(e.target.value, `surveys.${surveyType}.name`);
              }}
              placeholder='Enter name'
              required
              sx={{ mb: 3 }}
              value={surveyName}
            />
          </EditSurveyCard>
        </RightColumnLayout>
      ) : (
        <Navigate to='/surveys' />
      )}
    </ConditionalLoader>
  );
};
export default SurveyTemplateForm;
