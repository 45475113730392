import React, { useCallback, useMemo } from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';

import ChartTooltip from 'components/Events/Controls/Analytics/Charts/ChartTooltip';
import ChartWithLegend from 'components/shared/ChartWithLegend';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventNpsChartProps {
  data: EventAnalytic | undefined;
}

const EventNpsChart = ({ data }: EventNpsChartProps) => {
  const theme = useTheme();

  const basicData = useMemo(
    () => [
      {
        colorName: '#56ABB4',
        id: 'pos',
        label: `Promoters`,
        percent: `${
          data?.npsResponseRates?.promoter
            ? data?.npsResponseRates?.promoter
            : 0
        }%`,
        value: data?.npsResponseCounts?.promoter,
      },
      {
        colorName: '#E8C562',
        id: 'passive',
        label: `Passives`,
        percent: `${
          data?.npsResponseRates?.passive ? data?.npsResponseRates?.passive : 0
        }%`,
        value: data?.npsResponseCounts?.passive,
      },
      {
        colorName: '#D58F81',
        id: 'neg',
        label: `Detractors`,
        percent: `${
          data?.npsResponseRates?.detractor
            ? data?.npsResponseRates?.detractor
            : 0
        }%`,
        value: data?.npsResponseCounts?.detractor,
      },
    ],
    [data]
  );

  const formattedData = useMemo(
    () =>
      data && data?.nps != null
        ? basicData.map((item: any) => ({
            ...item,
            color: item.colorName,
            label: `${item.label}  ${item.percent}`,
            total: data?.npsResponseCounts?.total,
          }))
        : [
            {
              color: theme.palette.neutral.light,
              id: null,
              label: null,
              value: true,
            },
          ],
    [basicData, data, theme.palette]
  );

  const legendItems = useMemo(
    () =>
      basicData.map((item: any) => ({
        ...item,
        color: item.colorName,
        label: `${item.label}  ${item.percent}`,
        value: 1,
      })),
    [basicData]
  );

  const centeredMetric = useCallback(
    ({ centerX, centerY }: { centerX: any; centerY: any }) => (
      <text
        dominantBaseline='central'
        style={{
          fontSize: '16px',
          fontWeight: '500',
        }}
        textAnchor='middle'
        x={centerX}
        y={centerY - 10}
      >
        {data && data?.nps != null ? data?.nps : 'No data'}
      </text>
    ),
    [data]
  );

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Typography variant='body2'>Event NPS</Typography>
        <ChartWithLegend
          Chart={
            <ResponsivePie
              activeOuterRadiusOffset={6}
              animate
              arcLabel=''
              colors={{ datum: 'data.color' }}
              cornerRadius={3}
              data={formattedData}
              endAngle={90}
              fit
              innerRadius={0.4}
              isInteractive
              layers={['arcs', 'arcLabels', centeredMetric]}
              margin={{ bottom: 20, left: 10, right: 10, top: 20 }}
              startAngle={-90}
              theme={{
                text: { font: 'Inter, sans-serif', fontSize: 18 },
                tooltip: { container: { background: '#333' } },
              }}
              tooltip={(item) =>
                item?.datum.id ? (
                  <ChartTooltip obj={item?.datum} showCount textKey='label' />
                ) : null
              }
            />
          }
          legendItems={legendItems}
        />
      </CardContent>
    </Card>
  );
};

export default EventNpsChart;
