import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { RocketLaunch } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import EventPreview from 'components/Event/EventPreview';
import useEventFormValues from 'components/Event/useEventFormValues';
import { usePublishEvent } from 'hooks/usePublishEvent';
import useSaveEvent from 'hooks/useSaveEvent';
import {
  EventDetailsFormFormState,
  EventDetailsFormHandleSubmit,
  EventDetailsFormReset,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';
import FtnEvent from 'types/FtnEvent';

interface Props {
  eventId: number | null;
  formState: EventDetailsFormFormState;
  handleSubmit: EventDetailsFormHandleSubmit;
  isPublished: boolean;
  reset: EventDetailsFormReset;
  rsvpPauseActive?: boolean;
  watch: EventDetailsFormWatch;
}

const EventToolbar = ({
  eventId,
  formState,
  handleSubmit,
  isPublished,
  reset,
  rsvpPauseActive = false,
  watch,
}: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isClosing, setIsClosing] = useState(false);
  const [savedEvent, setSavedEvent] = useState<FtnEvent | undefined>(undefined);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const collectionId = searchParams.get('collection_id');
  const hasCollectionId = collectionId !== null;

  const { isDirty: hasChanges } = formState;

  const newFormValues = useEventFormValues(savedEvent);

  const invalidHandler = () => {
    enqueueSnackbar('Please resolve errors before saving your changes', {
      variant: 'error',
    });
  };

  const { isPending: isPublishing, mutate: publishMutate } = usePublishEvent({
    dataHandler: (returnedEvent) => {
      if (hasCollectionId) {
        navigate(`/collections/${collectionId}/details`, { replace: true });
        window.location.reload();
      } else {
        navigate(`/event/${returnedEvent.id}/details`, { replace: true });
      }
    },
  });

  // Publish Event
  const handlePublish = () => {
    const formValues = watch();

    if (hasCollectionId) {
      formValues.collectionId = collectionId;
    }

    handleSubmit(() => publishMutate(formValues), invalidHandler)();
  };

  // Save Draft
  const { isPending: isSaving, mutate: saveDraftMutate } = useSaveEvent({
    dataHandler: (returnedEvent) => {
      setSavedEvent(returnedEvent);
      if (returnedEvent.published === false && eventId == null) {
        // Redirect on first save
        navigate(`/event/${returnedEvent.id}/create`, { replace: true });
      }
    },
    eventId,
    saveAsDraft: true,
  });

  const buttonSize = 'medium';

  const handleCloseDialog = () => setIsClosing(false);

  const saveChanges = async () => {
    const formValues = watch();

    handleSubmit(() => saveDraftMutate(formValues), invalidHandler)();
    isClosing && handleCloseDialog();
  };

  useEffect(() => {
    if (savedEvent) {
      // If the saved event is available, reset the form values
      reset(newFormValues);
    }
  }, [savedEvent, newFormValues, reset]);

  const CancelButton = (
    <Button
      data-testid='close'
      onClick={() => {
        if (hasChanges) {
          setIsClosing(true);
        } else {
          if (hasCollectionId) {
            navigate(`/collections/${collectionId}/details`);
          } else {
            navigate('/events');
          }
        }
      }}
      size={buttonSize}
      sx={{ px: 2 }}
      variant='text'
    >
      Cancel
    </Button>
  );

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        gap={1.5}
        justifyContent='flex-end'
      >
        {CancelButton}
        <EventPreview
          isPublished={isPublished}
          rsvpPauseActive={rsvpPauseActive}
          watch={watch}
        />

        {!hasCollectionId && (
          <LoadingButton
            data-testid='save-draft'
            disabled={!hasChanges}
            loading={isSaving}
            onClick={saveChanges}
            size={buttonSize}
            sx={{ px: 2 }}
            variant='outlined'
          >
            Save draft
          </LoadingButton>
        )}
        <LoadingButton
          color='primary'
          data-testid='event-create-cta'
          loading={isPublishing}
          onClick={handlePublish}
          size={buttonSize}
          startIcon={<RocketLaunch />}
          variant='contained'
        >
          Publish event
        </LoadingButton>
      </Stack>
      <Dialog
        data-testid='unsaved-event-changes'
        onClose={handleCloseDialog}
        open={isClosing}
      >
        <DialogTitle>You have unsaved changes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {hasCollectionId
              ? 'Are you sure you want to discard your changes?'
              : 'Would you like to save them before closing?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='cancel'
            onClick={handleCloseDialog}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            data-testid='discard-changes'
            onClick={() => {
              handleCloseDialog();
              if (hasCollectionId) {
                navigate(`/collections/${collectionId}/details`);
              } else {
                navigate('/events');
              }
            }}
            variant='outlined'
          >
            {hasCollectionId ? 'Yes, discard' : 'No, discard'}
          </Button>
          {!hasCollectionId && (
            <Button
              data-testid='save-changes'
              onClick={() => {
                saveChanges();
              }}
              variant='contained'
            >
              Yes, save changes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EventToolbar;
