import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const EVENT_DUPLICATE_MUTATION = gql`
  mutation EventDuplicate($eventId: Int!, $numberOfCopies: Int!) {
    eventDuplicate(
      input: { eventId: $eventId, numberOfCopies: $numberOfCopies }
    ) {
      events {
        id
        name
        start
        ends
      }
      errors
    }
  }
`;

export const useCloneEvent = ({
  dataHandler = () => undefined,
}: {
  dataHandler?: () => unknown;
}) => {
  const graphQLClient = useGraphQLClient();

  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ copies, eventId }: { copies: number; eventId: string }) => {
      const response = graphQLClient.request(EVENT_DUPLICATE_MUTATION, {
        eventId: parseInt(eventId),
        numberOfCopies: copies,
      });
      return response;
    },
    onError: (error: any) => {
      enqueueSnackbar('Something went wrong when cloning the event', {
        variant: 'error',
      });
      Sentry.captureException(error);
    },
    onSuccess: () => {
      enqueueSnackbar(
        `The event has been duplicated! You can rename and update as needed, then publish it!`,
        { variant: 'success' }
      );

      dataHandler();
    },
  });
};
