import React, { useContext, useEffect, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const ManageNavbar = () => {
  const {
    actions: { setActiveTabIndex },
    state: { canEdit, groupId, manageTabs },
  } = useContext(ManagerContext);

  const tabs = useMemo(() => Object.values(manageTabs), [manageTabs]);
  const { tab: activeTab } = useParams();

  useEffect(() => {
    setActiveTabIndex(
      tabs && activeTab ? tabs.findIndex((t) => t.value === activeTab) : 0
    );
  }, [activeTab, setActiveTabIndex, tabs]);

  const queryParams = useMemo(
    () => (groupId ? `?group_id=${groupId}` : ``),
    [groupId]
  );

  return (
    <Tabs
      aria-label='Manage event tabs'
      className='event-analytics-pdf'
      scrollButtons='auto'
      value={tabs?.findIndex((t) => t.slug === activeTab) || 0}
      variant='scrollable'
    >
      {canEdit &&
        tabs.map((tab, index) => (
          <Tab
            component={NavLink}
            data-testid={`manage-tab-item-${tab.slug}`}
            key={index}
            label={tab.label}
            relative='path'
            to={`../${tab.slug}${queryParams}`}
            value={index}
            // sx={tab.hidden ? { visibility: 'hidden' } : {}}
          />
        ))}
    </Tabs>
  );
};
export default ManageNavbar;
