import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { Button } from '@mui/material';
import { SignOut } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import Head from 'components/Core/Head';
import EventCards from 'components/Events/EventCards';
import EditProfileModal from 'components/Profile/EditProfileModal';
import ProfileCardGoogleCalendar from 'components/Profile/ProfileCardGoogleCalendar';
import ProfileCardOutlookCalendar from 'components/Profile/ProfileCardOutlookCalendar';
import ProfileCardZoom from 'components/Profile/ProfileCardZoom';
import ProfileHeader from 'components/Profile/ProfileHeader';
import RightColumnLayout from 'components/shared/layout/RightColumnLayout';
import SurveyTemplateCards from 'components/Survey/SurveyTemplates/SurveyTemplateCards';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { ApplyFilterToChunk, useChunking } from 'utils/utils';

const Profile = () => {
  const profileEvents = useChunking('/api/users/events', 'events');
  const surveysChunk = useChunking('/api/survey_templates', 'templates');

  const {
    token: { setToken },
    userProvider: { isOrganizerOrAdmin, setUser, user },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { tab } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [org] = useContext(OrganizationContext);

  const mySurveysChunk = ApplyFilterToChunk(
    (survey) => survey.creator_id === user.id,
    surveysChunk
  );

  useMemo(() => {
    axios({ method: 'get', url: '/api/users/current' }, (res) => {
      setUser(res.data);
    });
  }, [setUser]);

  const removeFromChunks = (id) => {
    profileEvents.removeItemFromChunk(id);
  };

  const signOut = useCallback(() => {
    axios({ method: 'post', url: `/api/users/logout` }, () => {
      const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
      navigate('/login');
      setToken(null);
      if (amplitudeApiKey != null) {
        amplitude.reset();
      }
      enqueueSnackbar('You were logged out.', { variant: 'success' });
    });
  }, [enqueueSnackbar, navigate, setToken]);

  const tabs = [
    {
      component: (
        <EventCards
          chunk={profileEvents}
          inProfile
          removeFromAllChunks={removeFromChunks}
          tab={tab}
        />
      ),
      label: 'My events',
      value: 'user',
    },
    ...(isOrganizerOrAdmin
      ? [
          {
            component: (
              <SurveyTemplateCards chunk={mySurveysChunk} inProfile tab={tab} />
            ),
            label: 'My survey templates',
            value: 'surveyTemplates',
          },
        ]
      : []),
  ];

  const columnContent = useMemo(
    () => (
      <>
        <Button
          color='neutral'
          data-testid='edit-profile'
          onClick={() => setIsEditing(true)}
          variant='outlined'
        >
          Edit profile
        </Button>
        <Button
          color='neutral'
          data-testid='sign-out'
          endIcon={<SignOut />}
          onClick={() => signOut()}
          variant='outlined'
        >
          Sign out
        </Button>
        <EditProfileModal
          setShow={setIsEditing}
          setUser={setUser}
          show={isEditing}
          user={user}
        />
        {isOrganizerOrAdmin && (
          <>
            <ProfileCardZoom />
            {org.calendar_enabled === 'outlook' && (
              <ProfileCardOutlookCalendar />
            )}
            {org.calendar_enabled === 'google' && (
              <ProfileCardGoogleCalendar />
            )}
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing, setUser, user]
  );

  return (
    <>
      <Head title='Profile' />
      <RightColumnLayout
        columnContent={columnContent}
        fixedContent={<ProfileHeader columnContent={columnContent} />}
        fixedLocation='below-title'
        fixedSpacing={3}
        isTabsLayout
        tabs={tabs}
        title='Profile'
      />
    </>
  );
};
export default Profile;
