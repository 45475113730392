import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface CreateEventResponse {
  aiEventCreate: {
    event: { id: string } | null;
    status: string;
    error?: string;
  };
}

const CREATE_EVENT_MUTATION = gql`
  mutation AiEventCreate($input: AiEventCreateInput!) {
    aiEventCreate(input: $input) {
      event {
        id
      }
      status
      error
    }
  }
`;

export const useAiEventCreate = () => {
  const graphQLClient = useGraphQLClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (variables: {
      input: {
        eventType: string;
        eventTitle: string;
        eventDescription: string;
        eventDateTime?: string | null;
        eventLocation?: string | null;
        status: string;
      };
    }) => {
      const response = await graphQLClient.request<CreateEventResponse>(
        CREATE_EVENT_MUTATION,
        variables
      );

      if (response.aiEventCreate.error) {
        throw new Error(response.aiEventCreate.error);
      }

      return { event: response.aiEventCreate.event, status: response.aiEventCreate.status };
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to create event: ${error.message}`, { variant: 'error' });
      console.error('Event creation error:', error);
    },
    onSuccess: ({ event, status }: { event: { id: string } | null; status: string }) => {
      if (event?.id) {
        enqueueSnackbar(
          status === 'publish' ? 'Event successfully published!' : 'Event draft saved successfully!',
          { variant: 'success' }
        );
      }
    },
  });
};
