import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import EventBudgetSection from 'components/Events/Controls/Analytics/EventBudgetSection';
import EventGuestEngagementSection from 'components/Events/Controls/Analytics/EventGuestEngagementSection';
import EventGuestFeedbackSection from 'components/Events/Controls/Analytics/EventGuestFeedbackSection';
import EventOverviewSection from 'components/Events/Controls/Analytics/EventOverviewSection';
import useEventDateTimeRange from 'hooks/useEventDateTimeRange';
import { EventAnalytic } from 'types/EventAnalytic';
import { isOver } from 'utils/event';

const AnalyticsPDFExport = ({
  data,
  isExporting,
  pdfRef,
}: {
  data: EventAnalytic | undefined;
  isExporting: boolean;
  pdfRef: any;
}) => {
  const eventDateTimeRange = useEventDateTimeRange(data);
  const eventIsOver = isOver(data);

  return (
    <Box
      ref={pdfRef}
      sx={{
        height: isExporting ? '1123px' : '0',
        width: isExporting ? '1200px' : '0',
      }}
    >
      {isExporting && (
        <Stack direction='column' gap={1} justifyContent='flex-start'>
          <div className='pdf-section'>
            <Typography color='grey' fontSize='14px'>
              EVENT ANALYTICS
            </Typography>
            <Stack
              alignItems='center'
              direction='row'
              gap={1}
              justifyContent='space-between'
            >
              <Typography
                color='grey.900'
                sx={{ overflow: 'hidden' }}
                textOverflow='ellipsis'
                variant='h1'
                whiteSpace='nowrap'
              >
                {data?.name}
              </Typography>
              <Typography color='grey.600' component='div' variant='body2'>
                {eventDateTimeRange}
              </Typography>
            </Stack>
          </div>

          {/* Event Overview */}
          <div className='pdf-section'>
            <EventOverviewSection data={data} hideButton />
          </div>

          {/* Guest Engagement */}
          <div className='pdf-section'>
            <EventGuestEngagementSection data={data} hideButton />
          </div>

          {/* Budget Section */}
          <div className='pdf-section'>
            <EventBudgetSection data={data} hideButton />
          </div>

          {/* Feedback Section */}
          {eventIsOver && (
            <div className='pdf-section'>
              <EventGuestFeedbackSection data={data} hideButton />
            </div>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default AnalyticsPDFExport;
