import React, { useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

import AnalyticsPDFExport from 'components/Events/Controls/Analytics/AnalyticsPDFExport';
import EventBudgetSection from 'components/Events/Controls/Analytics/EventBudgetSection';
import EventGuestEngagementSection from 'components/Events/Controls/Analytics/EventGuestEngagementSection';
import EventGuestFeedbackSection from 'components/Events/Controls/Analytics/EventGuestFeedbackSection';
import EventOverviewSection from 'components/Events/Controls/Analytics/EventOverviewSection';
import { useEventAnalytics } from 'hooks/useEventAnalytics';
import { useEventAnalyticsCore } from 'hooks/useEventAnalyticsCore';
import { EventAnalytic } from 'types/EventAnalytic';
import { isOver } from 'utils/event';

interface EventAnalyticsProps {
  eventId: string;
}

export const EventAnalytics: React.FC<EventAnalyticsProps> = ({ eventId }) => {
  const [isExporting, setIsExporting] = useState(false);
  const pdfRef = useRef<HTMLDivElement>(null);
  const { data, error, isLoading } = useEventAnalyticsCore({ eventId });
  const unpackedInitialData = useMemo(() => data?.eventAnalytics, [data]);
  const isEventOver = useMemo(
    () => isOver(unpackedInitialData),
    [unpackedInitialData]
  );

  const { data: upcomingAnalytics } = useEventAnalytics({
    enabled: unpackedInitialData ? !isEventOver : false,
    eventId,
    upcoming: true,
  });

  const { data: pastAnalytics } = useEventAnalytics({
    enabled: unpackedInitialData ? isEventOver : false,
    eventId,
    upcoming: false,
  });

  const analyticsData: EventAnalytic | undefined = useMemo(() => {
    if (unpackedInitialData) {
      if (isEventOver) {
        return pastAnalytics?.eventAnalytics;
      } else {
        return upcomingAnalytics?.eventAnalytics;
      }
    }
  }, [isEventOver, pastAnalytics, unpackedInitialData, upcomingAnalytics]);

  const generatePDF = async () => {
    if (!pdfRef.current) return;

    setIsExporting(true); // Show the hidden component only during export

    setTimeout(async () => {
      const pdf = new JsPDF('p', 'mm', 'a4'); // Standard A4 dimensions
      const PAGE_WIDTH = 210; // mm
      const PAGE_HEIGHT = 297; // mm
      const MARGIN = 10;
      let yPosition = MARGIN;

      // Get all sections individually
      const sections = pdfRef.current?.querySelectorAll('.pdf-section') || [];

      for (let i = 0; i < sections.length; i++) {
        const section = sections?.[i];

        // Convert section to canvas
        const canvas = await html2canvas(section as any, {
          scale: 3,
          useCORS: true,
        });

        const imgWidth = PAGE_WIDTH - 2 * MARGIN;
        const imgHeight = (canvas.height / canvas.width) * imgWidth;

        // If adding this section exceeds page height, add new page
        if (yPosition + imgHeight > PAGE_HEIGHT - MARGIN) {
          pdf.addPage();
          yPosition = MARGIN; // Reset position for new page
        }

        // Add section image to PDF
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          MARGIN,
          yPosition,
          imgWidth,
          imgHeight
        );
        yPosition += imgHeight + 10; // Add space between sections
      }

      pdf.save(`${analyticsData?.name}_event_analytics.pdf`);
      setIsExporting(false);
    }, 700);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading event analytics</div>;
  }

  if (!data) {
    return <div>Loading event analytics...</div>;
  }

  return (
    <Stack>
      <Stack direction='column' justifyContent='flex-start' spacing={5}>
        <EventOverviewSection
          data={analyticsData}
          generatePDF={generatePDF}
          hideButton={false}
        />
        <EventGuestEngagementSection data={analyticsData} hideButton={false} />
        <EventBudgetSection data={analyticsData} hideButton={false} />
        {isEventOver && (
          <EventGuestFeedbackSection data={analyticsData} hideButton={false} />
        )}
      </Stack>
      <AnalyticsPDFExport
        data={analyticsData}
        isExporting={isExporting}
        pdfRef={pdfRef}
      />
    </Stack>
  );
};
