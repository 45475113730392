import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';

import GuestFeedback from 'components/Events/Controls/Analytics/GuestFeedback';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventGuestFeedbackSectionProps {
  data: EventAnalytic | undefined;
  hideButton: boolean;
}

const EventGuestFeedbackSection: React.FC<EventGuestFeedbackSectionProps> = ({
  data,
  hideButton,
}) => {
  const navigate = useNavigate();

  return (
    <Stack direction='column' gap={1.5}>
      <Stack
        alignItems='flex-end'
        direction='row'
        justifyContent='space-between'
      >
        <Typography component='h2' variant='h4'>
          Guest feedback
        </Typography>
        {!hideButton && (
          <Button
            className='analytics-section-button'
            data-testid='see-surveys'
            endIcon={<ArrowUpRight />}
            onClick={() => navigate(`/event/${data?.id}/surveys`)}
            variant='text'
          >
            See surveys
          </Button>
        )}
      </Stack>
      <GuestFeedback data={data} />
    </Stack>
  );
};

export default EventGuestFeedbackSection;
