import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';

import ChartWithLegend from 'components/shared/ChartWithLegend';
import CircularProgressWithLabel from 'components/shared/CircularProgressWithLabel';
import { Gradient } from 'typescript-color-gradient';

interface RsvpResponseSummaryChartProps {
  data?: any;
}

const RsvpResponseSummaryChart = ({ data }: RsvpResponseSummaryChartProps) => {
  const theme = useTheme();

  const colors = useMemo(() => {
    const colorData = theme.palette.primary;
    const mainColors = new Gradient()
      .setGradient(colorData?.dark, colorData?.main, colorData?.light)
      .setNumberOfColors(3)
      .getColors();
    const noResponseColor = '#F5F5F5';

    return mainColors.concat(noResponseColor);
  }, [theme.palette.primary]);

  const statusOrder = useMemo(
    () => ['rsvp', 'maybeAttending', 'notAttending', 'notResponded'],
    []
  );

  const statusLabel: any = useMemo(
    () => ({
      maybeAttending: 'Maybe',
      notAttending: 'No',
      notResponded: 'No response',
      rsvp: 'Yes',
    }),
    []
  );

  const responseSummaryData = useMemo(() => {
    if (!data?.statusCounts) return []; // Handle missing data

    const statusCounts = data.statusCounts;

    const allStatuses: any = {
      maybeAttending: statusCounts.maybeAttending || 0,
      notAttending: statusCounts.notAttending || 0,
      notResponded: statusCounts.notResponded || 0,
      rsvp: statusCounts.rsvp || 0,
    };

    const formattedData = [
      {
        status: 'RSVP Summary',
        ...statusOrder.reduce((acc: any, key, index) => {
          const label = statusLabel[key];
          acc[label] = allStatuses[key];
          acc[label + 'Color'] = colors[index];
          return acc;
        }, {}),
      },
    ];
    return formattedData;
  }, [data, colors, statusOrder, statusLabel]);

  const keys = useMemo(
    () =>
      statusOrder.map((status) => {
        const label = statusLabel[status];
        return label;
      }),
    [statusOrder, statusLabel]
  );

  const legendItems = useMemo(
    () =>
      keys.map((status, index) => ({
        color: colors[index],
        label: status,
        value: 1,
      })),
    [keys, colors]
  );

  return (
    <Card data-testid='rsvp-summary'>
      <CardContent sx={{ p: 3 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body2'>RSVP response summary</Typography>
          <Typography color='grey.400' variant='body2'>
            {data?.inviteesCount}{' '}
            {data?.inviteesCount > 0
              ? `invitee${data?.inviteesCount > 1 ? 's' : ''}`
              : 'employees invited'}
          </Typography>
        </Stack>
        <Box height={230}>
          {responseSummaryData.length > 0 ? (
            <ChartWithLegend
              Chart={
                <ResponsiveBar
                  animate={false}
                  axisBottom={null}
                  axisLeft={null}
                  axisRight={null}
                  axisTop={null}
                  borderRadius={4}
                  colors={(d) => d.data[d.id + 'Color']}
                  data={responseSummaryData}
                  enableGridX
                  enableGridY={false}
                  enableLabel={false}
                  groupMode='stacked'
                  indexBy='status'
                  keys={keys}
                  layout='horizontal'
                  margin={{ bottom: 60, left: 100, right: 100, top: 70 }}
                />
              }
              legendItems={legendItems}
              orientation='vertical'
            />
          ) : (
            <Stack
              alignItems='center'
              direction='column'
              height='100%'
              justifyContent='center'
            >
              <CircularProgressWithLabel
                isPlaceholder
                label='No data'
                size={180}
                thickness={10}
              />
            </Stack>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default RsvpResponseSummaryChart;
