import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { EventAnalytics } from 'components/Events/Steps/EventAnalytics';

const AnalyticsStep = ({ eventId }) => (
  <Stack direction='column' spacing={3}>
    <EventAnalytics eventId={eventId} />
  </Stack>
);
export default AnalyticsStep;

AnalyticsStep.propTypes = {
  eventId: PropTypes.string,
  handlePdfExport: PropTypes.func,
};
