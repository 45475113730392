import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface GenerateEventOption {
  eventTitle: string;
  eventDescription: string;
  eventDateTime?: string | null;
  eventLocation?: string | null;
}

interface GenerateEventResponse {
  aiEventOptionsGenerate: {
    eventOptions: GenerateEventOption[];
    error?: string;
  };
}

const AI_EVENT_OPTIONS_GENERATE_MUTATION = gql`
  mutation GenerateAiEventOptions($input: AiEventOptionsGenerateInput!) {
    aiEventOptionsGenerate(input: $input) {
      eventOptions {
        eventTitle
        eventDescription
        eventDateTime
        eventLocation
      }
      error
    }
  }
`;

export const useAiEventOptionsGenerate = (onSuccessCallback?: (options: GenerateEventOption[]) => void) => {
  const graphQLClient = useGraphQLClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (input: { input: { eventType: string; eventDescription: string; eventTones: string[] } }) => {
      const response = await graphQLClient.request<GenerateEventResponse>(
        AI_EVENT_OPTIONS_GENERATE_MUTATION,
        input
      );
      return response.aiEventOptionsGenerate.eventOptions;
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to generate event options: ${error.message}`, { variant: 'error' });
      console.error('Event options generation error:', error);
    },
    onSuccess: (data) => {
      if (!data) {
        enqueueSnackbar(`Error: No event options received`, { variant: 'error' });
        return;
      }
      enqueueSnackbar('Event options successfully generated!', { variant: 'success' });

      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
  });
};

