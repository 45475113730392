import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { gql } from 'graphql-request';

import Communications from 'components/Events/Controls/Communications/Communications';
import EventGuests from 'components/Events/Controls/GuestList/EventGuests';
import EventView from 'components/Events/Controls/Shared/EventView';
import Header from 'components/Events/Header/Header';
import EventEditRoute from 'components/Events/Manager/EventEditRoute';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import AnalyticsStep from 'components/Events/Steps/AnalyticsStep';
import DetailsStep from 'components/Events/Steps/DetailsStep';
import EventBudget from 'components/Events/Steps/EventBudget';
import SurveyStep from 'components/Events/Steps/SurveyStep';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import ProtectedRoute from 'components/shared/routes/ProtectedRoute';
import { useAuth } from 'hooks/useAuth';
import { useGraphQLClient } from 'hooks/useGraphQLClient';
import { isOver } from 'utils/event';
// const EventRoute = Sentry.withSentryRouting(Route);

const inviteeQueryDocument = gql`
  query inviteeQueryDocument($eventId: ID!, $email: String) {
    invitee(eventId: $eventId, email: $email) {
      id
      employeeId
      fullName
      status
    }
  }
`;
const EventManagerRoutes = () => {
  const {
    userProvider: { isOrganizerOrAdmin, user },
  } = useAuth();
  const {
    actions: { setCurrentPath, setPreviousPath },
    state: { canEdit, currentPath, isPublic, savedEvent },
  } = useContext(ManagerContext);

  const isBudgetFlagEnabled = useFlag('event-budget');

  const location = useLocation();
  const { id, tab } = useParams();

  const graphQLClient = useGraphQLClient({ isPublic: true });

  const { data, isFetched } = useQuery({
    enabled: user && !isOrganizerOrAdmin,
    queryFn: async () =>
      await graphQLClient?.request(inviteeQueryDocument, {
        email: user?.email,
        eventId: String(id),
        isPublic: true,
      }),
    queryKey: ['event', event?.id, 'invitee'],
  });

  const isInvited = useMemo(
    () => isFetched && data?.invitee !== null,
    [data?.invitee, isFetched]
  );

  useEffect(() => {
    if (currentPath && currentPath !== location.pathname) {
      setPreviousPath(currentPath);
    }
    setCurrentPath(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return tab === 'view' ? (
    <EventView />
  ) : !tab ? (
    <Navigate
      to={
        canEdit
          ? isOver(savedEvent)
            ? 'analytics'
            : 'details'
          : isPublic || isInvited
          ? 'view'
          : '/events'
      }
    />
  ) : (
    <EventEditRoute event={savedEvent}>
      {tab === 'edit' ? (
        <Navigate to='../details' />
      ) : tab === 'details' ? (
        <DetailsStep />
      ) : (
        <DefaultLayout header={<Header isManager />} noPadding title={null}>
          {tab === 'surveys' && <SurveyStep />}
          {tab === 'communications' && (
            <ProtectedRoute isAllowed>
              <Communications />
            </ProtectedRoute>
          )}
          {tab === 'guest_list' && <Navigate to='../guests' />}
          {tab === 'guests' && <EventGuests />}
          {tab === 'analytics' && <AnalyticsStep eventId={id} />}
          {tab === 'budget' && (
            <ProtectedRoute isAllowed={isBudgetFlagEnabled}>
              <EventBudget />
            </ProtectedRoute>
          )}
        </DefaultLayout>
      )}
    </EventEditRoute>
  );
};

export default EventManagerRoutes;
