import React from 'react';
import { useNavigate } from 'react-router-dom';
import Wordcloud from 'react-wordcloud';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ResponsiveBar } from '@nivo/bar';
import { ClipboardText, ClockUser } from '@phosphor-icons/react';

import InfoTooltip from 'components/shared/InfoTooltip';
import { EventAnalytic } from 'types/EventAnalytic';

interface GuestFeedbackProps {
  data: EventAnalytic | undefined;
}

const GuestFeedback: React.FC<GuestFeedbackProps> = ({ data }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const wordCloudData = Object.entries(
    data?.postEventSurvey?.feedbackWordCloud || {}
  ).map(([key, value]) => ({
    text: key,
    value: Number(value),
  }));

  const options = {
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    fontFamily: 'Arial',
    fontSizes: [20, 60] as [number, number],
    fontWeight: 'bold',
    padding: 5,
    rotation: 0,
    rotations: 0,
  };

  const npsLabels: { [key: string]: string } = {
    '0': 'Very Unlikely',
    '4': 'Unlikely',
    '7': 'Neutral',
    '9': 'Likely',
    '10': 'Very Likely',
  };

  const npsData =
    data?.npsGroupedByContent?.map(
      (item: { label: string; value: number }) => ({
        label: npsLabels[item.label] || item.label,
        value: item.value,
      })
    ) || [];

  const eventStarted = data?.start && new Date(data?.start) < new Date();
  const eventFinished = data?.ends && new Date(data?.ends) < new Date();
  const hasResponses =
    data?.totalUniquePostSurveyResponders &&
    data?.totalUniquePostSurveyResponders > 0;

  return data && eventFinished && hasResponses ? (
    <Grid container spacing={3}>
      <Grid size={{ md: 7, sm: 12, xs: 12 }}>
        <Card sx={{ overflow: 'visible' }}>
          <CardHeader
            title={
              <InfoTooltip
                content='The wordcloud visually represents key themes and most mentioned topics from post-event feedback.'
                text='Feedback summary word cloud'
                textVariant='body2'
              />
            }
          />
          <Box height={360} mt={2}>
            {wordCloudData.length > 0 ? (
              <Wordcloud options={options} words={wordCloudData} />
            ) : (
              <Typography align='center' sx={{ padding: 2 }} variant='body2'>
                No written response data available
              </Typography>
            )}
          </Box>
        </Card>
      </Grid>
      <Grid size={{ md: 5, sm: 12, xs: 12 }}>
        <Card sx={{ overflow: 'visible' }}>
          <CardHeader
            title={
              <Box
                alignItems='center'
                display='flex'
                justifyContent='space-between'
              >
                <Typography sx={{ flex: 1 }} variant='body2'>
                  NPS Grouped by Content
                </Typography>
                <Typography
                  sx={{ flex: 1, textAlign: 'right' }}
                  variant='body1'
                >
                  {`${data?.totalUniquePostSurveyResponders} responses`}
                </Typography>
              </Box>
            }
          />
          <Box height={360} mt={2} padding={1.5}>
            <ResponsiveBar
              axisLeft={{
                legend: 'Responses',
                legendOffset: -40,
                legendPosition: 'middle',
                tickValues: 'auto',
              }}
              colors={(d) => {
                switch (d.indexValue) {
                  case 'Very Unlikely':
                    return '#D58F81';
                  case 'Unlikely':
                    return '#D58F81';
                  case 'Neutral':
                    return '#D58F81';
                  case 'Likely':
                    return '#E8C562';
                  case 'Very Likely':
                    return '#56ABB4';
                  default:
                    return '#000000';
                }
              }}
              data={npsData}
              enableLabel={false}
              indexBy='label'
              keys={['value']}
              margin={{ bottom: 50, left: 50, right: 10, top: 10 }}
              padding={0.3}
              tooltip={({ color, indexValue, value }) => (
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '8px 12px',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: color,
                      display: 'inline-block',
                      height: '12px',
                      marginRight: '8px',
                      width: '12px',
                    }}
                  />
                  {indexValue}: <strong>{value}</strong>
                </div>
              )}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <Card>
      <CardContent sx={{ p: 3 }}>
        <Stack
          alignItems='center'
          direction='column'
          gap={2}
          justifyContent='center'
        >
          {data?.postEventSurvey ? (
            <ClockUser color={theme.palette.primary.main} size={32} />
          ) : (
            <ClipboardText color={theme.palette.primary.main} size={32} />
          )}
          <Typography variant='body2'>
            {data?.postEventSurvey
              ? 'Awaiting responses'
              : 'No post-event survey'}
          </Typography>
          <Typography variant='body1'>
            {data?.postEventSurvey
              ? eventFinished
                ? 'No one has responded to this survey yet'
                : eventStarted
                ? 'Event is currently ongoing, please check back later'
                : 'Event has not started yet, please check back later'
              : 'Create and send a post-event survey to see guest feedback'}
          </Typography>
          {!data?.postEventSurvey && (
            <Button
              color='primary'
              onClick={() => navigate(`/event/${data?.id}/surveys`)}
              variant='contained'
            >
              Create survey
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GuestFeedback;
