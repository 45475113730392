import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';

import GuestEngagement from 'components/Events/Controls/Analytics/GuestEngagement';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventGuestEngagementSectionProps {
  data: EventAnalytic | undefined;
  hideButton: boolean;
}

const EventGuestEngagementSection: React.FC<
  EventGuestEngagementSectionProps
> = ({ data, hideButton }) => {
  const navigate = useNavigate();

  return (
    <Stack direction='column' gap={1.5}>
      <Stack
        alignItems='flex-end'
        direction='row'
        justifyContent='space-between'
      >
        <Typography component='h2' variant='h4'>
          Guest engagement
        </Typography>
        {!hideButton && (
          <Button
            className='analytics-section-button'
            data-testid='see-guest-list'
            endIcon={<ArrowUpRight />}
            onClick={() => navigate(`/event/${data?.id}/guests`)}
            variant='text'
          >
            See guest list
          </Button>
        )}
      </Stack>
      <GuestEngagement data={data} />
    </Stack>
  );
};

export default EventGuestEngagementSection;
