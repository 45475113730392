import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowUpRight } from '@phosphor-icons/react';

import EventBudgetOverview from 'components/Event/Budget/EventBudgetOverview';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventBudgetSectionProps {
  data: EventAnalytic | undefined;
  hideButton: boolean;
}

const EventBudgetSection: React.FC<EventBudgetSectionProps> = ({
  data,
  hideButton,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/event/${data?.id}/budget`);
  };

  return (
    <Stack direction='column' gap={1.5}>
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <Typography component='h2' variant='h4'>
          Budget and spending
        </Typography>
        {!hideButton && (
          <Button
            className='analytics-section-button'
            endIcon={<ArrowUpRight />}
            onClick={handleNavigate}
            variant='text'
          >
            See budget
          </Button>
        )}
      </Stack>
      <EventBudgetOverview />
    </Stack>
  );
};

export default EventBudgetSection;
