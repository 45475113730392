import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Collapse, IconButton, Stack, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  CopySimple,
  Link,
  Spinner,
  Trash,
} from '@phosphor-icons/react';
import { UseMutateFunction } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import EventDuplicateDialog from 'components/shared/EventDuplicateDialog';
import { LayoutContext } from 'contexts/LayoutContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import {
  EventDetailsFormData,
  EventDetailsFormFormState,
  EventDetailsFormHandleSubmit,
  EventDetailsFormReset,
} from 'types/EventDetailsForm';
import { getEventLink } from 'utils/event';

interface DetailsActionsProps {
  cancelDuplicate: () => void;
  cloneEvent: UseMutateFunction<number, Error, number, unknown>;
  copies: number;
  disableFields: boolean;
  eventId: number;
  eventName: string;
  formState: EventDetailsFormFormState;
  handleSubmit: EventDetailsFormHandleSubmit;
  hasStarted: boolean;
  isLoading: boolean;
  isPublic: boolean;
  reset: EventDetailsFormReset;
  saveEvent: UseMutateFunction<
    EventDetailsFormData,
    Error,
    EventDetailsFormData,
    unknown
  >;
  setCopies: any;
  showDeleteModal: () => void;
  showDuplicate: boolean;
  startDuplicate: () => void;
}

const DetailsActions = ({
  cancelDuplicate,
  cloneEvent,
  copies,
  disableFields,
  eventId,
  formState,
  handleSubmit,
  hasStarted,
  isLoading,
  saveEvent,
  setCopies,
  showDeleteModal,
  showDuplicate,
  startDuplicate,
}: DetailsActionsProps) => {
  const { isMobile } = useContext(LayoutContext);

  const { copy } = useCopyToClipboard();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isAnimating, setIsAnimating] = useState(false);

  const { isDirty } = formState;

  const invalidHandler = () => {
    enqueueSnackbar('Please resolve errors before saving your changes', {
      variant: 'error',
    });
  };

  const handleSaveEvent = () => {
    handleSubmit((d) => saveEvent(d), invalidHandler)();
  };

  const onlyGroupsIsDirty = useMemo(() => {
    const dirtyFieldNames = Object.keys(formState.dirtyFields || {});
    return dirtyFieldNames?.length === 1 && dirtyFieldNames[0] === 'groups';
  }, [formState]);

  return (
    <Stack direction='row' justifyContent='flex-end' spacing={1}>
      {isMobile && (
        <Tooltip data-testid='back-to-events' title='Back to events'>
          <span>
            <IconButton
              data-testid='back-button'
              onClick={() => navigate('/events')}
            >
              <ArrowLeft />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!hasStarted && (
        <Tooltip data-testid='delete-event' title='Delete event'>
          <span>
            <IconButton
              data-testid='delete-button'
              disabled={disableFields}
              onClick={showDeleteModal}
            >
              <Trash />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip data-testid='clone-event' sx={{ order: 4 }} title='Clone event'>
        <span>
          <IconButton data-testid='clone-event' onClick={startDuplicate}>
            <CopySimple />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip data-testid='copy-event-link' title='Copy event link'>
        <span>
          <IconButton
            data-testid='copy-event-link'
            onClick={() =>
              copy(
                getEventLink('event', undefined, { id: eventId }) || '',
                'event link'
              )
            }
          >
            <Link />
          </IconButton>
        </span>
      </Tooltip>
      <Collapse
        in={isDirty && !onlyGroupsIsDirty}
        onAnimationEnd={() => setIsAnimating(false)}
        onAnimationStart={() => setIsAnimating(true)}
        orientation='horizontal'
        unmountOnExit
      >
        <LoadingButton
          {...(isLoading || (!isDirty && isAnimating)
            ? {
                loadingPosition: 'start',
                startIcon: <Spinner />,
              }
            : {})}
          color='primary'
          data-testid='save-changes'
          loading={isLoading || (!isDirty && isAnimating)}
          onClick={handleSaveEvent}
          sx={{ whiteSpace: 'nowrap' }}
          variant='contained'
        >
          {isLoading ? 'Saving' : 'Save changes'}
        </LoadingButton>
      </Collapse>
      <EventDuplicateDialog
        copies={copies}
        handleClose={cancelDuplicate}
        handleCopiesChange={setCopies}
        handleDuplicate={() => cloneEvent(eventId)}
        isWorking={isLoading}
        open={showDuplicate}
      />
    </Stack>
  );
};

export default DetailsActions;
