import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

interface ManageCollectionButtonProps {
  action: string;
  buttonIcon: React.ReactNode;
  color: 'primary' | 'secondary' | 'warning';
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
  secondaryText: string;
  tooltipText?: string;
}

const ManageCollectionButton: React.FC<ManageCollectionButtonProps> = ({
  action,
  buttonIcon,
  color,
  customStyle,
  disabled,
  onClick,
  secondaryText,
  tooltipText,
}) => (
  <Tooltip placement='top' title={disabled ? tooltipText : ''}>
    <Paper
      elevation={0}
      onClick={!disabled ? onClick : undefined}
      sx={{
        '&:hover': {
          border: '1px solid',
          borderColor: color + '.main',
        },
        alignItems: 'center',
        backgroundColor: 'white',
        border: '1px solid lightgrey',
        borderRadius: 2,
        cursor: disabled ? 'default' : 'pointer',
        display: 'flex',
        width: '390px',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: `${color}.light`,
          borderColor: `${color}.main`,
          borderRadius: '12% 0 0 12%',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '15%',
        }}
      >
        <IconButton color={color} style={customStyle}>
          {buttonIcon}
        </IconButton>
      </Box>
      <Stack ml={1} p={1} spacing={0.5}>
        <Typography variant='body2'>{action}</Typography>
        <Typography color='text.secondary' variant='body1'>
          {secondaryText}
        </Typography>
      </Stack>
    </Paper>
  </Tooltip>
);

export default ManageCollectionButton;
