import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSnackbar } from 'notistack';
import { useGraphQLClient } from 'hooks/useGraphQLClient';

interface GraphQLError {
  message: string;
}

interface GenerateEventData {
  eventTitle: string;
  eventDescription: string;
  eventDateTime: string | null;
  eventLocation: string | null;
  eventImageLink: string | null;
  error?: string;
}

interface GenerateEventResponse {
  eventAiGenerate: GenerateEventData;
}

const EVENT_AI_GENERATE_MUTATION = gql`
  mutation GenerateEvent($eventType: String!, $eventDescription: String!, $eventTones: [String!]!) {
    eventAiGenerate(eventType: $eventType, eventDescription: $eventDescription, eventTones: $eventTones) {
      eventTitle
      eventDescription
      eventDateTime
      eventLocation
      eventImageLink
      error
    }
  }
`;

export const useEventAiGenerate = (onSuccessCallback?: (eventData: GenerateEventData) => void) => {
  const graphQLClient = useGraphQLClient();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (input: { eventType: string; eventDescription: string; eventTones: string[] }) => {
      const response = await graphQLClient.request<{ data: GenerateEventResponse }>(
        EVENT_AI_GENERATE_MUTATION,
        input
      );
      return response.data.eventAiGenerate; // Ensure we return only the AI-generated event data
    },
    onError: (error: any) => {
      enqueueSnackbar(`Failed to generate event: ${error.message}`, {
        variant: 'error',
      });
      console.error('Event generation error:', error);
    },
    onSuccess: (eventAiGenerate: GenerateEventData) => {
      if (eventAiGenerate.error) {
        enqueueSnackbar(`Error: ${eventAiGenerate.error}`, { variant: 'error' });
        return;
      }

      enqueueSnackbar('Event successfully generated!', { variant: 'success' });

      // Optional: Update event cache
      queryClient.invalidateQueries({ queryKey: ['events'] });

      // Trigger success callback if provided
      if (onSuccessCallback) {
        onSuccessCallback(eventAiGenerate);
      }
    },
  });
};
