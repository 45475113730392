import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  CalendarStar,
  CheckCircle,
  DownloadSimple,
  PiggyBank,
  UserCirclePlus,
} from '@phosphor-icons/react';

import EventSummaryCard from 'components/Events/Controls/Analytics/EventSummaryCard';
import { EventAnalytic } from 'types/EventAnalytic';

interface EventSummaryUpcomingProps {
  data: EventAnalytic | undefined;
  generatePDF: any;
  hideButton: boolean;
}

const EventSummaryUpcoming: React.FC<EventSummaryUpcomingProps> = ({
  data,
  generatePDF,
  hideButton,
}) => {
  const amountCents = data?.budget?.amountCents || 0;
  const actualSpendCents = data?.budget?.actualSpendCents || 0;
  let circleColor = 'transparent';

  if (amountCents > 0) {
    circleColor =
      actualSpendCents > amountCents ? 'error.main' : 'primary.main';
  }

  return (
    <Stack direction='column' gap={1.5}>
      <Stack
        alignItems='flex-end'
        direction='row'
        justifyContent='space-between'
      >
        <Typography component='h2' variant='h4'>
          Overview
        </Typography>
        {!hideButton && (
          <Button
            className='analytics-section-button'
            data-testid='export-analytics-data'
            endIcon={<DownloadSimple />}
            onClick={() => generatePDF()}
            variant='text'
          >
            Export analytics
          </Button>
        )}
      </Stack>
      <Grid container spacing={3}>
        <EventSummaryCard
          icon={<CalendarStar />}
          title={
            data?.hoursUntilEvent && data?.hoursUntilEvent > 24
              ? 'Days until event'
              : 'Hours until event'
          }
          value={
            data?.hoursUntilEvent && data?.hoursUntilEvent > 24
              ? data?.daysUntilEvent || 0
              : data?.hoursUntilEvent && data?.hoursUntilEvent < 0
              ? 'Ongoing'
              : data?.hoursUntilEvent || 0
          }
        />

        <EventSummaryCard
          icon={<UserCirclePlus />}
          title='Invitations sent'
          value={data?.inviteesCount || 0}
        />

        <EventSummaryCard
          icon={<CheckCircle />}
          title='Accepted RSVPs'
          value={data?.statusCounts?.rsvp || 0}
        />

        <EventSummaryCard
          circleColor={circleColor}
          icon={<PiggyBank />}
          title='Current spend'
          value={`$${(data?.budget?.actualSpendCents || 0) / 100}`}
        />
      </Grid>
    </Stack>
  );
};

export default EventSummaryUpcoming;
